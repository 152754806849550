import React, { useState, useEffect } from "react";
import { Variables } from "../../Variations/Variations";
import DataTable from "react-data-table-component";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineEdit, AiOutlineRight } from "react-icons/ai";
import Loader from "../../Layout/Loader";
import ReactTooltip from "react-tooltip";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { MdManageSearch, MdOutlineDelete } from "react-icons/md";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Util from "../../Classes/Util";
import Cookies from "js-cookie";

const ListSizeChart = () => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const token  = Cookies.get("UserToken");

  const [universalSizeChart, setUniversalSizeChart] = useState([]);
  const [relatedSizeChart, setRelatedSizeChart] = useState([]);
  const [search, setSearch] = useState("");
  const [universalLoading, setUniversalLoading] = useState(true);
  const [relatedLoading, setRelatedLoading] = useState(true);
  const [isOpenDeleteUniversal, setIsOpenDeleteUniversal] = useState(0);
  const [isOpenDeleteRelated, setIsOpenDeleteRelated] = useState(0);
  const navigate = useNavigate();

  const universalColumns = [
    {
      name: "ID",
      selector: (row) => row.SizeChartId,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.CategoryName,
      sortable: true,
    },
    {
      name: "Attribute",
      selector: (row) =>
        row.AttributeValueName === "" ? "-" : row.AttributeValueName,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          {permissions.ManageSizeChart && (<NavLink className="btn btn-outline-secondary btn-sm"
            to={
              "/universal-size-chart/" +
              row.SizeChartId +
              "/category/" +
              row.CategoryId
            }
          >
            <AiOutlineEdit data-tip={"Manage Size Chart"} />
            <ReactTooltip place="bottom" className="tooltip" effect="solid" />
          </NavLink>)}
          {permissions.DeleteSizeChart && (<button
            className="btn btn-outline-danger btn-sm ms-2"
            onClick={() => setIsOpenDeleteUniversal(row.SizeChartId)}
          >
            <MdOutlineDelete data-tip="Delete Size Chart" />
          </button>)}
          <ReactTooltip place="bottom" className="tooltip" effect="solid" />
          <Modal
            show={row.SizeChartId === isOpenDeleteUniversal}
            onHide={() => setIsOpenDeleteUniversal(0)}
          >
            <Modal.Header>
              <Modal.Title>Delete Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <p>
                    Are you sure you want to
                    <b> Delete</b> the size chart with category named{" "}
                    <b>{row.CategoryName}</b>{" "}
                    {row.AttributeValueName !== "" &&
                      " and with attribute value named " +
                      row.AttributeValueName}
                    ?
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="container">
                <div className="row">
                  <div className="col-8"></div>
                  <div className="col-2">
                    <Button
                      variant="primary"
                      className="form-control"
                      onClick={() => DeleteUniversalSizeChart(row.SizeChartId)}
                    >
                      Yes
                    </Button>
                  </div>
                  <div className="col-2">
                    <Button
                      variant="secondary"
                      className="form-control"
                      onClick={() => setIsOpenDeleteUniversal(0)}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];

  const relatedColumns = [
    {
      name: "ID",
      selector: (row) => row.SizeChartId,
      sortable: true,
    },

    {
      name: "Brand",
      selector: (row) => row.BrandName,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.CategoryName,
      sortable: true,
    },
    {
      name: "Attribute",
      selector: (row) =>
        row.AttributeValueName === "" ? "-" : row.AttributeValueName,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          {permissions.ManageSizeChart && (<NavLink className="btn btn-outline-secondary btn-sm"
            to={
              "/size-chart/" + row.SizeChartId + "/category/" + row.CategoryId
            }
          >
            <AiOutlineEdit data-tip={"Manage Size Chart"} />
            <ReactTooltip place="bottom" className="tooltip" effect="solid" />
          </NavLink>)}
          {permissions.DeleteSizeChart && (<button
            className="btn btn-outline-danger btn-sm ms-2"
            onClick={() => setIsOpenDeleteRelated(row.SizeChartId)}
          >
            <MdOutlineDelete data-tip="Delete Size Chart" />
          </button>)}
          <ReactTooltip place="bottom" className="tooltip" effect="solid" />
          <Modal
            show={row.SizeChartId === isOpenDeleteRelated}
            onHide={() => setIsOpenDeleteRelated(0)}
          >
            <Modal.Header>
              <Modal.Title>Delete Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <p>
                    Are you sure you want to
                    <b> Delete</b> the size chart with category named{" "}
                    <b>{row.CategoryName}</b>{" "}
                    {row.AttributeValueName !== "" &&
                      " and with attribute value named " +
                      row.AttributeValueName}
                    ?
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="container">
                <div className="row">
                  <div className="col-8"></div>
                  <div className="col-2">
                    <Button
                      variant="primary"
                      className="form-control"
                      onClick={() => DeleteRelatedSizeChart(row.SizeChartId)}
                    >
                      Yes
                    </Button>
                  </div>
                  <div className="col-2">
                    <Button
                      variant="secondary"
                      className="form-control"
                      onClick={() => setIsOpenDeleteRelated(0)}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "View Records",
    rangeSeparatorText: "out",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const DeleteUniversalSizeChart = async (SizeChartId) => {
    setUniversalLoading(true);
    let response = await fetch(
      Variables.API_URL + "SizeChart/DeleteSizeChart/" + SizeChartId,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          "Access-Control-Allow-Origin":
            "https://sheyaakatesting.sheyaaka.com/",
        },
      }
    );
    if (response.ok === false) {
      const result = await response.json();
      toast.error(result);
    } else {
      toast.success("Successfully deleted");

      setUniversalSizeChart([]);

      response = await fetch(
        Variables.API_URL + "SizeChart/GetUniversalCharts",{
          headers:{
            "Authorization": `Bearer ${token}`,
          }
        }
      );
      const result = await response.json();
      setUniversalSizeChart(result);
      setUniversalLoading(false);
    }
    setIsOpenDeleteUniversal(0);
  };

  const DeleteRelatedSizeChart = async (SizeChartId) => {
    setRelatedLoading(true);
    let response = await fetch(
      Variables.API_URL + "SizeChart/DeleteSizeChart/" + SizeChartId,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin":
            "https://sheyaakatesting.sheyaaka.com/",
        },
      }
    );
    if (response.ok === false) {
      const result = await response.json();
      toast.error(result);
    } else {
      toast.success("Successfully deleted");

      setRelatedSizeChart([]);

      response = await fetch(
        Variables.API_URL + "SizeChart/GetSpecificCharts",{
          headers:{
            "Authorization": `Bearer ${token}`,
          }
        }
      );
      const result = await response.json();
      setRelatedSizeChart(result);
      setRelatedLoading(false);
    }
    setIsOpenDeleteRelated(0);
  };

  const GetUniversalSizeChart = async () => {
    const response = await fetch(
      Variables.API_URL + "SizeChart/GetUniversalCharts",{
        headers:{
          "Authorization": `Bearer ${token}`,
        }
      }
    );
    const result = await response.json();
    setUniversalSizeChart(result);
    setUniversalLoading(false);
    setSearch("");
  };

  const GetRelatedSizeChart = async () => {
    const response = await fetch(
      Variables.API_URL + "SizeChart/GetSpecificCharts",{
        headers:{
          "Authorization": `Bearer ${token}`,
        }
      }
    );
    const result = await response.json();
    setRelatedSizeChart(result);
    setRelatedLoading(false);
    setSearch("");
  };

  useEffect(() => {
   if(Cookies.get("UserToken") !== undefined){
    GetUniversalSizeChart();
   } else {
    navigate("/Login");
   }
  }, []);

  return (
    <div className="rightSide">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2>Size Chart</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li>Size Chart</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">

              <div className="card-body">
                <div className="container-fluid">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="universal"
                  >
                    <div className="row">
                      <Nav variant="pills" className="flex-row">
                        <div className="nav-media-col">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="universal"
                              onClick={GetUniversalSizeChart}
                            >
                              Universal
                            </Nav.Link>
                          </Nav.Item>
                        </div>
                        <div className="nav-media-col">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="related"
                              onClick={GetRelatedSizeChart}
                            >
                              Related
                            </Nav.Link>
                          </Nav.Item>
                        </div>
                      </Nav>
                      <div className="separator separator-solid mb-7 mt-7"></div>
                    </div>
                    <div className="row">
                      <Tab.Content>
                        <Tab.Pane eventKey="universal">
                          <div className="row mt-4 mb-3">
                            <div className="col-4 text-end tblSearchBar position-relative">
                              <span>
                                <MdManageSearch />
                              </span>
                              <input
                                type="text"
                                placeholder="Search any field in table"
                                className="form-control form-control-sm"
                                onChange={(e) => setSearch(e.target.value)}
                              ></input>
                            </div>
                            <div className="col-8 text-end">
                              {permissions.ManageSizeChart && (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    navigate("/universal-size-chart/category")
                                  }
                                >
                                  New Record
                                </button>
                              )}
                            </div>
                          </div>
                          {universalLoading ? (
                            <Loader />
                          ) : (
                            <div className="portalTable">
                              <DataTable
                                columns={universalColumns}
                                data={universalSizeChart.filter((chart) =>
                                  chart.CategoryName.toLowerCase().includes(
                                    search.toLowerCase()
                                  ) ||
                                  chart.AttributeValueName.toLowerCase().includes(
                                    search.toLowerCase()
                                  )
                                )}
                                pagination
                                paginationComponentOptions={
                                  paginationComponentOptions
                                }
                              />
                            </div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="related">
                          <div className="row mt-4 mb-3">
                            <div className="col-4 text-end tblSearchBar position-relative">
                              <span>
                                <MdManageSearch />
                              </span>
                              <input
                                type="text"
                                placeholder="Search any field in table"
                                className="form-control form-control-sm"
                                onChange={(e) => setSearch(e.target.value)}
                              ></input>
                            </div>
                            <div className="col-8 text-end">
                              {permissions.ManageSizeChart && (
                                <button
                                  className="btn btn-primary"
                                  onClick={() => navigate("/size-chart/category")}
                                >
                                  New Record
                                </button>
                              )}
                            </div>
                          </div>
                          {relatedLoading ? (
                            <Loader />
                          ) : (
                            <div className="portalTable">
                              <DataTable
                                columns={relatedColumns}
                                data={relatedSizeChart.filter(
                                  (chart) =>
                                    chart.CategoryName.toLowerCase().includes(
                                      search.toLowerCase()
                                    ) ||
                                    chart.AttributeValueName.toLowerCase().includes(
                                      search.toLowerCase()
                                    ) ||
                                    chart.BrandName.toLowerCase().includes(
                                      search.toLowerCase()
                                    )
                                )}
                                pagination
                                paginationComponentOptions={
                                  paginationComponentOptions
                                }
                              />
                            </div>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListSizeChart;
