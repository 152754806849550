import React from "react";
import { useEffect, useState } from "react";
import { Button, Modal, Tabs, Tab } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Variables } from "../../Variations/Variations";
import Util from "../../Classes/Util";
import Cookies from "js-cookie";
function SlideGallery(props) {
  const values = [true, "sm-down", "md-down", "lg-down", "xl-down", "xxl-down"];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  console.log("Permession", permissions);
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const [Media, setMedia] = useState([]);
  const navigate = useNavigate();
  const token = Cookies.get("UserToken");

  const handleDrop = async (files) => {
    debugger
    var count = 0;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      if (files[i].size >= 2000000) {
        count = count + 1;
      } else {
        reader.onload = async function () {
          const formData = new FormData();
          formData.append("Image", files[i]);
          try {
            const res = await axios.post(
              Variables.API_URL + "UploadMedia/UploadImageToServer",
              formData, {
                headers: {
                  "Authorization": `Bearer ${token}`,
                }
              }
            );
            if (res.data !== "") {
              await fetch(Variables.API_URL + "UploadMedia/InsertMedia", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({
                  image: res.data,
                  imageTitle: files[i].name.split(".").slice(0, -1).join("."),
                  altText: "",
                  description: "",
                  caption: "",
                }),
              }).then((Response) => {
                if (Response.ok) {
                  fetchMedia();
                } else {
                  toast.error(Response);
                }
              });
            }
          } catch (ex) {
            console.log(ex);
          }
        };
        reader.readAsDataURL(file);
      }
    }

    if (count === 1) {
      var error1 = " Image is not accepted ";
      toast.error(error1);
    } else if (count > 1) {
      var error2 = count + " Images are not accepted ";
      toast.error(error2);
    }
  };

  function fetchMedia() {
    fetch(Variables.API_URL + "UploadMedia/GetMedia", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
      .then((Response) => Response.json())
      .then((Result) => {
        setMedia(Result);
      });
  }
  useEffect(() => {
    fetchMedia();
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <label> Slide Media </label>
        <br/>
        <Button
          className="btn btn-secondary btn-sm w-100"
          onClick={() => handleShow(true)}
          id="media"
        >
          Add Slider Media
        </Button>
      </div>


      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Media to slider</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="Library"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            {permissions.ManageSlide === true ? (
              <Tab eventKey="Upload" title="Upload File">
                <div className="row mt-5">
                  <div className="row">
                    <Dropzone
                      onDrop={handleDrop}
                      accept="image/*,video/*"
                      style={{ height: 500 }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <p style={{ height: 500 }}>
                            Drag'n'drop images, or click to select files
                          </p>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  <div className="row">
                    <br />
                  </div>
                </div>
              </Tab>
            ) : null}
            <Tab eventKey="Library" title="Media Library">
              <div className="row">
                <div className="container-fluid">
                  <div className="row">
                    {Media.map((image) => (
                      <div className="col-2 p-10" key={image.VendorMediaId}>
                        <div className="imagecontainer">
                          <button
                            className="btn-icon"
                            onClick={(e) => (
                              props.setMedia(image.MediaId),
                              props.setShowingMedia(image.Image),
                              setShow(false)
                            )}
                          >
                            <img
                              alt="media"
                              className="form-control"
                              src={Variables.API_PUBLISHED + image.Image}
                            />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SlideGallery;