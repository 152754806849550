
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Variables } from "../../Variations/Variations";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";

const ManageTags = () => {
    let { TagsID } = useParams();
    let navigate = useNavigate();
    const token = Cookies.get("UserToken");
    const [tagName, setTagName] = useState()
    const [tagDescription, setTagDescription] = useState()
    const [slug, setSlug] = useState('');
    const [tags, setTags] = useState([])


    function IsValid() {
        if (tagName == null) {
            toast.error("Tag Name Required");
            document.getElementById("txtTagName").focus();
            return false;
        }
        if (slug == null) {
            toast.error("SLUG Required");
            setTimeout(function () { document.getElementById("txtTagSlug").focus() }, 300);

            return false;
        }
        return true
    }


    function HandleName(e) {


        setTagName(e.target.value);

        if (tagName != null) {
            setSlug((e.target.value).replace(/ /g, "-"));
        }

    }

    function HandleSlug(e) {
        setSlug((e.target.value).replace(/ /g, "-"));
    }



    function HandleSubmit(e) {
        //debugger;
        e.preventDefault();
        if (IsValid() == true) {
            if (TagsID == 0) {
                const URL = Variables.API_URL + "Tags/AddTag";
                fetch(URL, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        "Authorization": `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Name: tagName,
                        Description: tagDescription,
                        Slug: slug,
                        IsPublshed: false

                    })
                }).then(response => {
                    if (response.ok) {
                        navigate('/Tags');

                    }
                    else {
                        toast.error(response);
                    }
                })
            }
            else {

                const URL = Variables.API_URL + "Tags/UpdateTag/" + TagsID;
                fetch(URL, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        "Authorization": `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Name: tagName,
                        Description: tagDescription,
                        Slug: slug

                    })
                }).then(response => {
                    if (response.ok) {
                        navigate('/Tags');

                    }
                    else {
                        toast.error(response);
                    }
                })

            }
        }
    }
    async function GetTags(tagID) {
        const URL = Variables.API_URL + "Tags/GetTagByID/" + tagID;
        const response = await fetch(URL,{
            headers:{
                "Authorization": `Bearer ${token}`,
            }
        });
        if (!response.ok) throw Error("Did Not Receive expected data");
        const listItems = await response.json();
        setTagName(listItems.Name);
        setSlug(listItems.Slug);
        setTagDescription(listItems.Description)
        console.log(listItems)
    }


    useEffect(() => {
        if(Cookies.get("UserToken") !== undefined){
            if (TagsID != 0) {
                GetTags(TagsID);
            }
        } else {
            navigate("/Login");
        }
    }, [])

    return (
        <div className="rightSide">
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-12">
                        <h2>{TagsID == "0" ? "Add" : "Update"} Tag</h2>
                        <ul className="portalPath">
                            <li>Home <AiOutlineRight /></li>
                            <li><Link to="/Tags">Tags <AiOutlineRight /></Link></li>
                            <li>Manage Tag</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {/* <form> */}
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h3>{tagName !== '' ? tagName : ""}</h3>
                                    </div>
                                    <div className="col-lg-4 text-right">
                                        <NavLink to='/Tags' className='btn btn-secondary'>Cancel</NavLink>
                                        <button type="submit" className="btn btn-primary ms-2" onClick={HandleSubmit}>Save</button>
                                    </div>

                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <label className="mb-2">Tag Name <span className="required">*</span></label>
                                                <input className="form-control" id="txtTagName" onChange={HandleName} value={tagName !== '' ? tagName : ""} />
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <label className="mb-2">Slug<span className="required">*</span></label>
                                                <input className="form-control" id="txtTagSlug" onChange={HandleSlug} value={slug !== '' ? slug : ""} />
                                            </div>
                                            <div className="col-lg-12 mt-3 mb-3">
                                                <label className="mb-2">Description</label>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={tagDescription}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setTagDescription(data);
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ManageTags;
