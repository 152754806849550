import React, { useState, useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom"
import DataTable from 'react-data-table-component';
import { Variables } from "../../Variations/Variations"
import { BiTrash, BiEdit, BiShowAlt, BiHide } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import Util from "../../Classes/Util";
import { AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
const CartPriceRules = () => {
    const [pricerules, setpricerules] = useState([]);
    const permissions = Util.decrypt(localStorage.getItem("Permissions"));
    const token = Cookies.get("UserToken");
    const navigate = useNavigate();
    function TooglePublishCartPriceRule(id) {
        const URL = Variables.API_URL + "CartPriceRule/TooglePublishCartPriceRule/" + id;
        fetch(URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (response.ok) {
                GetCartPriceRules();
                toast.success("Action Done")
            }
            else {
                toast.error("Error, please check with administrator");
            }
        });
    }

    const columns = [
        {
            name: 'ID',
            selector: row => row.CartPriceRuleId,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Price Rule Name',
            selector: row => row.CartPriceRuleName,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Type',
            selector: row => "Cart",
            sortable: true,
            reorder: true,
        },
        {
            name: 'Options',
            selector: row => <div>
                {permissions.PublishPriceRule && (<button className="btn btn-outline-secondary btn-sm" onClick={() => TooglePublishCartPriceRule(row.CartPriceRuleId)}>{row.IsPublished ? <BiHide /> : <BiShowAlt />} </button>)}
                {permissions.ManagePriceRule && (<NavLink to={"/PriceRules/" + row.CartPriceRuleId} className="btn btn-outline-warning btn-sm ms-2" ><BiEdit /></NavLink>)}

            </div>,
        }
    ];

    async function GetCartPriceRules() {
        const URL = Variables.API_URL + "CartPriceRule/GetCartPriceRules";
        const response = await fetch(URL,{
            headers:{
                "Authorization": `Bearer ${token}`,
            }
        })
        if (!response.ok) throw Error("Did Not Receive expected data");
        const listItems = await response.json();
        setpricerules(listItems);
    }


    //#region Use Effect 
    useEffect(() => {
        if(Cookies.get("UserToken") !== undefined){
            GetCartPriceRules();
        } else {
            navigate("/Login");
        }
    }, [])
    //#endregion Use Effect 
    return (
        <div className="rightSide">
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-12">
                        <h2>List Cart Price Rules</h2>
                        <ul className="portalPath">
                            <li>Home <AiOutlineRight /></li>
                            <li>List Cart Price Rules</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {permissions.ManagePriceRule && (<div className="mb-3 text-right">
                                    <NavLink to='/PriceRules/0' className="btn btn-primary">New Cart Price Rule</NavLink>
                                </div>)}
                                <div className="portalTable">
                                    <DataTable
                                        columns={columns}
                                        data={pricerules}
                                        pagination
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick />
            </div>
        </div>
    )
}
export default CartPriceRules;