import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Util from "../Classes/Util";
import {
  RiPagesLine,
  RiRulerLine,
  RiSettings3Fill,
  RiDashboardFill,
} from "react-icons/ri";
import { HiMenu } from "react-icons/hi";
import { GrProductHunt } from "react-icons/gr";
import { BiFontSize, BiMinus } from "react-icons/bi";
import { BsPerson, BsCart2 } from "react-icons/bs";
import { SiBrandfolder, SiGroupon, SiMaterialdesign } from "react-icons/si";
import { BiCategoryAlt, BiSlideshow, BiLogOut } from "react-icons/bi";
import {
  AiOutlineTags,
  AiFillSliders,
  AiOutlineAreaChart,
  AiFillFlag,
} from "react-icons/ai";
import {
  MdOutlineCardGiftcard,
  MdWidgets,
  MdOutlineNotificationsActive,
  MdContentCopy,
} from "react-icons/md";
import { GoFileMedia, GoPlusSmall } from "react-icons/go";
import { GiPriceTag } from "react-icons/gi";
import logo from "../Images/whiteLogo.png";

const SideBar = (props) => {
  const [product, setProduct] = useState(false);
  const [coupon, setCoupon] = useState(false);
  const [shipping, setShipping] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [settings, setSettings] = useState(false);
  const [layout, setLayout] = useState(false);
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  function handleLogout() {
    // sessionStorage.clear();
    localStorage.clear();
    props.setIsAuthenticated(false);
    window.location.href = "/Login";
  }
  return (
    <>
      <aside>
        <ul>
          {permissions.Dashboard && (
            <>
              <li>
                <NavLink to="/">
                  <img src={logo} alt="Sis Logo" className="asidelogo" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/">
                  <span className="icon">
                    <RiDashboardFill />
                  </span>
                  <span> Dashboard</span>
                </NavLink>
              </li>
            </>
          )}
          {permissions.Notification && (
            <>
              <li>
                <NavLink to={"/notification"} className="notification-icon">
                  <span className="icon">
                    {" "}
                    <MdOutlineNotificationsActive />
                  </span>{" "}
                  <span> Notification</span>{" "}
                  <span className="notification-label">
                    {
                      props.notifications.filter(
                        (notification) => !notification.IsOpened
                      ).length
                    }
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/ContactUs"} className="notification-icon">
                  <span className="icon">
                    {" "}
                    <MdOutlineNotificationsActive />
                  </span>{" "}
                  <span> Contact Us</span>{" "}
                  <span className="notification-label">
                    {
                      props.contactUs.filter(
                        (notification) => !notification.IsOpened
                      ).length
                    }
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/PushNotification"} className="notification-icon">
                  <span className="icon">
                    {" "}
                    <MdOutlineNotificationsActive />
                  </span>{" "}
                  <span> Push Notification</span>{" "}
                  <span className="notification-label">
                    {
                      props.pushNotification
                    }
                  </span>
                </NavLink>
              </li>
            </>
          )}

          {(permissions.Pages ||
            permissions.Menus ||
            permissions.Groups ||
            permissions.Sliders ||
            permissions.Slides ||
            permissions.Widgets) && (
            <li className="mt-20">
              <span className="title">
                Layout{" "}
                <button
                  className="btn plusSideBar"
                  onClick={() => setLayout(!layout)}
                >
                  {layout == false ? <GoPlusSmall /> : <BiMinus />}{" "}
                </button>
              </span>
            </li>
          )}
          {layout && (
            <>
              {permissions.Pages && (
                <li>
                  <NavLink to="/Pages">
                    <span className="icon">
                      <RiPagesLine />
                    </span>
                    <span> Pages</span>
                  </NavLink>
                </li>
              )}
              {permissions.Contents && (
                <li>
                  <NavLink to="/Contents">
                    <span className="icon">
                      <MdContentCopy />
                    </span>
                    <span> Contents</span>
                  </NavLink>
                </li>
              )}
              {permissions.Menus && (
                <li>
                  <NavLink to="/Menus">
                    <span className="icon">
                      <HiMenu />
                    </span>
                    <span> Menus</span>
                  </NavLink>
                </li>
              )}
              {permissions.Groups && (
                <li>
                  <NavLink to="/Groups">
                    <span className="icon">
                      <SiGroupon />
                    </span>
                    <span> Menu Groups</span>
                  </NavLink>
                </li>
              )}
              {permissions.Sliders && (
                <li>
                  <NavLink to="/Sliders">
                    <span className="icon">
                      <AiFillSliders />
                    </span>
                    <span> Sliders</span>
                  </NavLink>
                </li>
              )}
              {permissions.Slides && (
                <li>
                  <NavLink to="/Slides">
                    <span className="icon">
                      <BiSlideshow />
                    </span>
                    <span> Slides</span>
                  </NavLink>
                </li>
              )}
              {permissions.Widgets && (
                <li>
                  <NavLink to="/Widgets">
                    <span className="icon">
                      <MdWidgets />
                    </span>
                    <span> Widgets</span>
                  </NavLink>
                </li>
              )}
            </>
          )}

          {(permissions.Products ||
            permissions.Attributes ||
            permissions.Brands ||
            permissions.Categories ||
            permissions.Materials ||
            permissions.Tags ||
            permissions.Media ||
            permissions.Measurements ||
            permissions.SizeCharts) && (
            <li className="mt-20">
              <span className="title">
                Product{" "}
                <button
                  className="btn plusSideBar"
                  onClick={() => setProduct(!product)}
                >
                  {" "}
                  {product == false ? <GoPlusSmall /> : <BiMinus />}{" "}
                </button>
              </span>
            </li>
          )}
          {product && (
            <>
              {permissions.Products && (
                <li>
                  <NavLink to="/Products">
                    <span className="icon">
                      <GrProductHunt />
                    </span>
                    <span> Products</span>
                  </NavLink>
                </li>
              )}
              {permissions.Attributes && (
                <li>
                  <NavLink to="/Attributes">
                    <span className="icon">
                      <BiFontSize />
                    </span>
                    <span> Attributes</span>
                  </NavLink>
                </li>
              )}
              {permissions.Brands && (
                <li>
                  <NavLink to="/Brand">
                    <span className="icon">
                      <SiBrandfolder />
                    </span>
                    <span> Brands</span>
                  </NavLink>
                </li>
              )}
              {permissions.Categories && (
                <li>
                  <NavLink to="/Category">
                    <span className="icon">
                      <BiCategoryAlt />
                    </span>
                    <span> Categories</span>
                  </NavLink>
                </li>
              )}

              {permissions.Materials && (
                <li>
                  <NavLink to="/Materials">
                    <span className="icon">
                      <SiMaterialdesign />
                    </span>
                    <span> Materials</span>
                  </NavLink>
                </li>
              )}
              {permissions.Tags && (
                <li>
                  <NavLink to="/Tags">
                    <span className="icon">
                      <AiOutlineTags />
                    </span>
                    <span> Tags</span>
                  </NavLink>
                </li>
              )}

              {permissions.Media && (
                <li>
                  <NavLink to="/Media">
                    <span className="icon">
                      <GoFileMedia />
                    </span>
                    <span> Media</span>
                  </NavLink>
                </li>
              )}

              {permissions.Measurements && (
                <li>
                  <NavLink to="/Measurements">
                    <span className="icon">
                      <RiRulerLine />
                    </span>
                    <span>Measurements</span>
                  </NavLink>
                </li>
              )}

              {/* {permissions.SizeCharts && (<li>
                <NavLink to="/size-chart">
                  <span className="icon">
                    <AiOutlineAreaChart />
                  </span>
                  <span> Size Chart</span>
                </NavLink>
              </li>)} */}
            </>
          )}

          {(permissions.Coupons ||
            permissions.CouponGroups ||
            permissions.PriceRules) && (
            <li className="mt-20">
              <span className="title">
                Coupon
                <button
                  className="btn plusSideBar"
                  onClick={() => setCoupon(!coupon)}
                >
                  {coupon == false ? <GoPlusSmall /> : <BiMinus />}
                </button>
              </span>
            </li>
          )}
          {coupon && (
            <>
              {permissions.Coupons && (
                <li>
                  <NavLink to="/Coupons">
                    <span className="icon">
                      <MdOutlineCardGiftcard />
                    </span>
                    <span> Coupons</span>
                  </NavLink>
                </li>
              )}
              {permissions.CouponGroups && (
                <li>
                  <NavLink to="/CouponGroups">
                    <span className="icon">
                      <SiGroupon />
                    </span>
                    <span> Coupon Groups</span>
                  </NavLink>
                </li>
              )}

              {permissions.PriceRules && (
                <li>
                  <NavLink to="/PriceRules">
                    <span className="icon">
                      <GiPriceTag />
                    </span>
                    <span> Price Rules</span>
                  </NavLink>
                </li>
              )}

              {permissions.Countries && (
                <li>
                  <NavLink to="/Countries">
                    <span className="icon">
                      <AiFillFlag />
                    </span>
                    <span> Countries</span>
                  </NavLink>
                </li>
              )}
            </>
          )}

          {/* <li className="mt-20">
              <span className="title">Shipping <button className="btn plusSideBar" onClick={()=>setShipping(!shipping)}> {shipping ==false ?  <GoPlusSmall/> : <BiMinus/>} </button></span>
            </li>
            {shipping && (<>
          <li>
            <NavLink to="/Senders">
              <span className="icon">
                <MdLocalShipping  />
              </span>
              <span> Senders</span>
            </NavLink>
          </li>
          </>)} */}
          {(permissions.Customers || permissions.Orders) && (
            <li className="mt-20">
              <span className="title">
                Customer{" "}
                <button
                  className="btn plusSideBar"
                  onClick={() => setCustomer(!customer)}
                >
                  {customer == false ? <GoPlusSmall /> : <BiMinus />}{" "}
                </button>
              </span>
            </li>
          )}
          {customer && (
            <>
              {permissions.Customers && (
                <li>
                  <NavLink to="/Customers">
                    <span className="icon">
                      <BsPerson />
                    </span>
                    <span> Customers</span>
                  </NavLink>
                </li>
              )}
              {permissions.Orders && (
                <li>
                  <NavLink to="/Orders">
                    <span className="icon">
                      <BsCart2 />
                    </span>
                    <span> Orders</span>
                  </NavLink>
                </li>
              )}
            </>
          )}

          {permissions.Settings && (
            <>
              <li className="mt-20">
                <span className="title">
                  Settings{" "}
                  <button
                    className="btn plusSideBar"
                    onClick={() => setSettings(!settings)}
                  >
                    {" "}
                    {settings == false ? <GoPlusSmall /> : <BiMinus />}{" "}
                  </button>
                </span>
              </li>
              {settings && (
                <>
                  <li>
                    <NavLink to="/Settings">
                      <span className="icon">
                        <RiSettings3Fill />
                      </span>
                      <span> Settings</span>
                    </NavLink>
                  </li>
                </>
              )}
              <br />
            </>
          )}
          <li>
            <button onClick={handleLogout}>
              <span className="icon logoutSideBar">
                <BiLogOut />
              </span>
              <span className="logoutSpan"> Logout</span>
            </button>
          </li>
        </ul>
      </aside>
    </>
  );
};
export default SideBar;
