import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Variables } from "../../Variations/Variations";
import axios from "axios";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { AiOutlineRight } from 'react-icons/ai'
import Cookies from "js-cookie";

const ManageContent = (props) => {
  const [content, setContent] = useState({
    ContentId: 0,
    Name: "",
    Html: "",
    PageId: 0,
    IsPublished: true
  });
  let { ContentID } = useParams();
  const navigate = useNavigate();
  const token = Cookies.get("UserToken");
  const [pages, setPages] = useState([])


  function uploadAdapter(loader) {
    debugger
    return {
      upload: () => {
        return new Promise((resolve) => {
          const formData = new FormData();

          loader.file.then(async (file) => {
            formData.append("image", file);
            formData.append("sender", "A");
            formData.append("senderID", 0);

            const response = await axios.post(
              Variables.API_URL + "UploadMedia/UploadImageToServer",
              formData,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Authorization": `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": Variables.API_URL,
                },
              }
            );
            resolve({
              default: `${Variables.API_PUBLISHED}${response.data}`,
            });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const fetchContent = async () => {
    if (parseInt(ContentID) !== 0) {
      const response = await fetch(
        Variables.API_URL + "Content/GetContent/" + ContentID,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Variables.API_URL,
          },
        }
      );
      const result = await response.json();
      setContent({
        ContentId: result.ContentId,
        Name: result.Name,
        Html: result.Html,
        PageId: result.PageId,
        IsPublished: result.IsPublished
      });
    }
  };
  const fetchPages = async () => {

    const response = await fetch(
      Variables.API_URL + "Pages/GetPages",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    const result = await response.json();
    setPages(
      result
    );

  };
  const handleSubmit = async () => {
    if (ContentID == 0) {

      let response = await fetch(Variables.API_URL + "Content/AddContent", {
        method: "Post",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://api.playmegastorelb.com/api/",
        },
        body: JSON.stringify({
          Name: content.Name,
          IsPublished: content.IsPublished,
          Html: content.Html,
          PageId: content.PageId
        }),
      });
      let result = await response.json();
      if (response.ok) {
        navigate("/Contents");
        toast.success(result);
      } else {
        toast.error(result);
      }

    } else {
      let response = await fetch(
        Variables.API_URL + "Content/UpdateContent",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://api.playmegastorelb.com/api/",
          },
          body: JSON.stringify({
            Name: content.Name,
            IsPublished: content.IsPublished,
            Html: content.Html,
            PageId: content.PageId,
            ContentId: ContentID
          }),
        }
      );
      let result = await response.json();
      if (response.ok) {
        toast.success(result);

        setTimeout(() => {
          navigate("/Contents");
        }, 600);
      } else {
        toast.error(result);
      }



    }
  };

  useEffect(() => {
    if(Cookies.get("UserToken") !== undefined){
      fetchContent();
    fetchPages();
    }else {
      navigate("/Login");
    }
  }, []);

  return (
    <div className="rightSide">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2>Manage Content: {content.Name}</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Contents">Contents <AiOutlineRight /></Link></li>
              <li>Manage Content</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {/* <div className="card-header">
                <div className="row">
                  <div className="col-6">
                    <h5 className="card-title">Content</h5>
                  </div>
                  <div className="col-6 text-right">

                  </div>
                </div>
              </div> */}
              <div className="card-body">
                <div className="text-end">
                  <NavLink className="btn btn-secondary" to="/contents">
                    Back
                  </NavLink>
                  <Button
                    variant="primary"
                    className="ms-2"
                    onClick={
                      handleSubmit
                    }
                  >
                    Submit
                  </Button>
                </div>
                <div className="form-group row mb-4">
                  <div className="col-6">
                    <label className="mb-2">Title </label>{" "}
                    <span className="requiredinput">*</span>
                    <input
                      type="text"
                      id="Name"
                      className="form-control"
                      value={content.Name}
                      placeholder="Title"
                      onChange={(e) =>
                        setContent((prevState) => ({
                          ...prevState,
                          Name: e.target.value,
                        }))
                      }
                      required
                    ></input>
                  </div>

                  <div className="form-check col-2" style={{ marginTop: "30px" }}>
                    <label className="form-check-label mb-2" htmlFor="IsPublished">
                      Published
                    </label>
                    <input
                      type="checkbox"
                      id="IsPublished"
                      className="form-check-input"
                      value={content.IsPublished}
                      checked={content.IsPublished}
                      onChange={(e) =>
                        setContent((prevState) => ({
                          ...prevState,
                          IsPublished: e.target.checked,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <div className="col-6">
                    <label className="mb-2">Page </label>{" "}
                    <span className="requiredinput">*</span>


                    <select className="form-control" value={content.PageId} onChange={(e) =>
                      setContent((prevState) => ({
                        ...prevState,
                        PageId: e.target.value,
                      }))
                    }>
                      <option value={0}>--Select--</option>
                      {pages.map((p, i) => {
                        return (
                          <option value={p.PageId} key={i}>{p.Title} </option>
                        )
                      })}
                    </select>
                  </div>


                </div>

                <div className="form-group row">
                  <div className="col-12">
                    <label className="mb-2">Description</label>
                    <span className="requiredinput">*</span>
                    <CKEditor
                      config={{
                        extraPlugins: [uploadPlugin],
                      }}
                      editor={ClassicEditor}
                      data={content.Html}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setContent((prevState) => ({
                          ...prevState,
                          Html: data,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageContent;
