import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Loader from "../../Layout/Loader";
import { Variables } from "../../Variations/Variations";
import { MdManageSearch, MdOutlineArrowRightAlt } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import { AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";

const ListCategoriesForSizeChart = () => {
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const token = Cookies.get("UserToken");

  const categoryColumns = [
    {
      name: "ID",
      selector: (row) => row.CategoryId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.CategoryName,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <NavLink className="btn btn-outline-secondary btn-sm"
          to={
            window.location.pathname.includes("universal-size-chart")
              ? "/universal-size-chart/0/category/" + row.CategoryId
              : "/size-chart/0/category/" + row.CategoryId
          }
        >
          <MdOutlineArrowRightAlt
            className=""
            data-tip="Manage Size Chart"
          />
          <ReactTooltip place="bottom" className="tooltip" effect="solid" />
        </NavLink>
      ),
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "View Records",
    rangeSeparatorText: "out",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const GetCategories = async () => {
    const response = await fetch(
      Variables.API_URL + "Category/GetCategories"
    );
    const result = await response.json();
    setCategories(result);
    setLoading(false);
  };

  useEffect(() => {
    GetCategories();
  }, []);

  return (
    <div className="rightSide">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2>Choose Category</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/size-chart">Size Chart <AiOutlineRight /></Link></li>
              <li>Choose Category</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-4 text-end tblSearchBar position-relative">
                    <span>
                      <MdManageSearch />
                    </span>
                    <input
                      type="text"
                      placeholder="Search any field in table"
                      className="form-control form-control-sm"
                      onChange={(e) => setSearch(e)}
                    ></input>
                  </div>
                  <div className="col-8 mb-3 text-end">
                    <NavLink className="btn btn-secondary" to="/size-chart">
                      Back
                    </NavLink>
                  </div>
                </div>
                {loading ? (
                  <Loader />
                ) : (
                  <div className="portalTable">
                    <DataTable
                      columns={categoryColumns}
                      data={categories.filter((category) =>
                        category.CategoryName.toLowerCase().includes(
                          search.toLowerCase()
                        )
                      )}
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListCategoriesForSizeChart;
