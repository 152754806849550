import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Variables } from "../../Variations/Variations"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "js-cookie";
const ManageAttributes = () => {
    let { AttributeID } = useParams();
    let navigate = useNavigate();
    const token = Cookies.get("UserToken");
    const [attributeName, setAttributeName] = useState();
    const [attributeType, setAttributeType] = useState();
    async function GetAttribute(attributeID) {
        const URL = Variables.API_URL + "/Attribute/GetAttribute/" + attributeID;
        const response = await fetch(URL,{
            headers:{
                "Authorization": `Bearer ${token}`,
            }
        });
        if (!response.ok) throw Error("Did Not Receive expected data");
        const listItems = await response.json();
    }
    function IsValid(){
        if(attributeName != null){
            
            return true;
        }
        return false;
    }
    function HandleSubmit(e) {
        if (IsValid()) {
            e.preventDefault();
            const URL = Variables.API_URL + "Attribute/AddAttribute";
            fetch(URL, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    AttributeName: attributeName,
                    IncludeInFilter:true
                })
            })
            navigate('/Category')
        }
        else {
            toast.error("Please fill all required fields!")
        }
    }

    useEffect(() => {
        if (AttributeID != 0) {
            GetAttribute(AttributeID);
        }
    }, [])
    return (
        <div className="rightSide">
        <div className="container-fluid mt-3">
            <div className="row">
                <div className="col-12">
                    <form>
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h3>{AttributeID == 0 ? 'Add' : 'Update'} Attribute</h3>
                                    </div>
                                    <div className="col-lg-4 text-right">
                                        <NavLink to='/Attribute' className='btn btn-secondary'>Cancel</NavLink>
                   
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-3">
                                       
                                    </div>
                                    <div className="col-lg-9">
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick />
        </div>
        </div>
    )
}
export default ManageAttributes;