import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { NavLink, useNavigate } from "react-router-dom";
import { Variables } from "../../Variations/Variations";
import { BiTrash, BiEdit, BiShowAlt, BiHide } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineClose, AiOutlineCheck, AiOutlineRight } from "react-icons/ai";
import { Button, Modal } from "react-bootstrap";
import Util from "../../Classes/Util";
import Cookies from "js-cookie";

const Groups = () => {
  const [groups, setGroups] = useState([]);
  const [show, setShow] = useState(0);
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const token = Cookies.get("UserToken");
  const navigate = useNavigate();

  const columns = [
    {
      name: "ID",
      selector: (row) => row.GroupId,
      sortable: true,
    },
    {
      name: "Group Name",
      selector: (row) => row.GroupName,
      sortable: true,
    },
    {
      name: "Published",
      selector: (row) =>
        row.IsPublished ? <AiOutlineCheck /> : <AiOutlineClose />,
      sortable: true,
    },
    {
      name: "Options",
      selector: (row) => (
        <div>
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => TooglePublishGroup(row.GroupId)}
          >
            {row.IsPublished ? <BiHide /> : <BiShowAlt />}{" "}
          </button>
          {permissions.ManageGroup && (<NavLink to={"/Groups/" + row.GroupId} className="btn btn-outline-warning btn-sm ms-2">
            <BiEdit />
          </NavLink>)}
          {permissions.DeleteGroup && (<button className="btn btn-outline-danger btn-sm ms-2" onClick={() => setShow(row.GroupId)}>
            <BiTrash />
          </button>
          )}
          <Modal show={row.GroupId === show} onHide={() => setShow(0)}>
            <Modal.Header>
              <Modal.Title>Delete Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <p>
                    Are you sure you want to
                    <b> Delete</b> the menu group named <b>{row.GroupName}</b>?
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="container">
                <div className="row">
                  <div className="col-8"></div>
                  <div className="col-2">
                    <Button
                      variant="primary"
                      className="form-control"
                      onClick={() => DeleteGroup(row.GroupId)}
                    >
                      Yes
                    </Button>
                  </div>
                  <div className="col-2">
                    <Button
                      variant="secondary"
                      className="form-control"
                      onClick={() => setShow(0)}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];
  async function TooglePublishGroup(id) {
    let response = await fetch(
      Variables.API_URL + "Group/TooglePublishGroup/" + id,
      {
        method: "Put",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://api.playmegastorelb.com/api/",
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetGroups();
    } else {
      toast.error(result);
    }
  }
  async function DeleteGroup(id) {
    let response = await fetch(Variables.API_URL + "Group/DeleteGroup/" + id, {
      method: "Delete",
      headers: {
        Accept: "application/json",
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://api.playmegastorelb.com/api/",
      },
    });
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetGroups();
    } else {
      toast.error(result);
    }
  }

  async function GetGroups() {
    const URL = Variables.API_URL + "Group/GetGroups";
    const response = await fetch(URL,{
      headers:{
        "Authorization": `Bearer ${token}`,
      }
    });
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setGroups(listItems);
  }
  //#region Use Effect
  useEffect(() => {
    if(Cookies.get("UserToken") !== undefined){
      GetGroups();
    } else {
      navigate("/Login");
    }
  }, []);
  //#endregion Use Effect
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>List Groups</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li>List Groups</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {/* <div className="card-header">
                <div className="row">
                  <div className="col-lg-8">
                    <h3>List Groups</h3>
                  </div>
                </div>
              </div> */}
              <div className="card-body">
                {permissions.ManageGroup && (<div className=" text-end mb-3">
                  <NavLink to="/Groups/0" className="btn btn-primary">
                    Add Group
                  </NavLink>
                </div>
                )}
                <div className="portalTable">
                  <DataTable columns={columns} data={groups} pagination />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
        />
      </div>
    </div>
  );
};
export default Groups;
