import React, { useState, useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom"
import DataTable from 'react-data-table-component';
import { Variables } from "../../Variations/Variations"
import { BiTrash, BiEdit, BiShowAlt, BiHide } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import Util from "../../Classes/Util";
import { AiOutlineClose, AiOutlineCheck, AiOutlineRight } from 'react-icons/ai'
import Cookies from "js-cookie";

const Coupons = () => {
    const [coupons, setCoupons] = useState([]);
    const permissions = Util.decrypt(localStorage.getItem("Permissions"));
    const token = Cookies.get("UserToken");
    const navigate= useNavigate();
    async function TooglePublishCoupon(id) {
        let response = await fetch(
            Variables.API_URL + "Coupon/TooglePublishCoupon/" + id,
            {
                method: "Put",
                headers: {
                    Accept: "application/json",
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "https://api.playmegastorelb.com/api/",
                },
            }
        );
        let result = await response.json();
        if (response.ok) {
            toast.success(result);
            GetCoupons();
        } else {
            toast.error(result);
        }
    }


    const columns = [
        {
            name: 'Coupon ID',
            selector: row => row.CouponId,
            sortable: true,
        },
        {
            name: 'Coupon Name',
            selector: row => row.CouponName,
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row.TypeName,
            sortable: true,
        },
        {
            name: 'Published',
            selector: row => row.IsPublished ? <AiOutlineCheck /> : <AiOutlineClose />,
            sortable: true,
        },
        {
            name: 'Options',
            selector: row => <div>
                {permissions.PublishCoupon && (<button className="btn btn-outline-secondary btn-sm" onClick={() => TooglePublishCoupon(row.CouponId)}>{row.IsPublished ? <BiHide /> : <BiShowAlt />} </button>)}
                {permissions.ManageCoupon && (<NavLink to={"/Coupons/" + row.CouponId} className="btn btn-outline-warning btn-sm ms-2" ><BiEdit /></NavLink>)}

            </div>,
        }
    ];

    async function GetCoupons() {
        const URL = Variables.API_URL + "Coupon/GetCoupons";
        const response = await fetch(URL,{
            headers:{
                "Authorization": `Bearer ${token}`,
            }
        })
        if (!response.ok) throw Error("Did Not Receive expected data");
        const listItems = await response.json();
        setCoupons(listItems);
    }


    //#region Use Effect 
    useEffect(() => {
        if(Cookies.get("UserToken") !== undefined){
            GetCoupons();
        } else {
            navigate("/Login");
        }
    }, [])
    //#endregion Use Effect 
    return (
        <div className="rightSide">
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-12">
                        <h2>List Coupons</h2>
                        <ul className="portalPath">
                            <li>Home <AiOutlineRight /></li>
                            <li>List Coupons</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {permissions.ManageCoupon && (<div className="mb-3 text-right">
                                    <NavLink to='/Coupons/0' className="btn btn-primary">New Coupon</NavLink>
                                </div>)}
                                <div className="portalTable">
                                    <DataTable
                                        columns={columns}
                                        data={coupons}
                                        pagination
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick />
            </div>
        </div>
    )
}
export default Coupons;