import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { Link, NavLink, useNavigate } from "react-router-dom"
import { Variables } from "../../Variations/Variations";
import { BiTrash, BiEdit } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import Util from "../../Classes/Util";
import { AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
const ListUsers = () => {
    const [users, setUsers] = useState();
    const permissions = Util.decrypt(localStorage.getItem("Permissions"));
    const token = Cookies.get("UserToken");
    const navigate = useNavigate();
    const columns = [
        {
            name: 'ID',
            selector: row => row.UserId
        },
        {
            name: 'User Name',
            selector: row => row.Username,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Options',
            selector: row => <div>
                {permissions.ManageUser === true ? (<NavLink to={"/Users/" + row.UserId} className="btn btn-outline-secondary btn-sm" ><BiEdit /></NavLink>) : null}
                {permissions.DeleteUser === true ? (<button className="btn btn-outline-danger btn-sm ms-2" onClick={() => DeleteUser(row.UserId)}><BiTrash /></button>) : null}
            </div>,
        }
    ];

    function DeleteUser(id) {
        const URL = Variables.API_URL + "User/DeleteUser/" + id;
        fetch(URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (response.ok) {
                GetUsers();
                toast.success("User removed")
            }
            else {
                toast.error("Error, please check with administrator");
            }
        });
    }

    async function GetUsers() {
        const URL = Variables.API_URL + "User/GetUsers";
        const response = await fetch(URL,{
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
        if (!response.ok) throw Error("Did Not Receive expected data");
        const listItems = await response.json();
        setUsers(listItems);
    }
    //#region Use Effect 
    useEffect(() => {
        if(Cookies.get("UserToken") !== undefined){
        GetUsers();

        } else {
            navigate("/Login");
        }
    }, [])
    //#endregion Use Effect 
    return (
        <div className="rightSide">
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-12">
                        <h2>List Users</h2>
                        <ul className="portalPath">
                            <li>Home <AiOutlineRight /></li>
                            <li><Link to="/Settings">Settings <AiOutlineRight /></Link></li>
                            <li>List Users</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-2 text-right">
                                    {permissions.ManageUser === true ? (<NavLink to='/Users/0' className='btn btn-primary'>Add User</NavLink>) : null}
                                </div>
                                <div className="portalTable">
                                    <DataTable
                                        columns={columns}
                                        data={users}
                                        pagination />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick />
            </div>
        </div>
    )
}
export default ListUsers;