import React, { useState, useEffect } from "react";
import { Button, Collapse, Modal, Form } from "react-bootstrap";
import { BiTrash, BiEdit } from "react-icons/bi";
import { toast } from "react-toastify";
import { Variables } from "../../../Variations/Variations";
import VariationGallery from "./VariationGallery";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { DropdownDate, DropdownComponent } from "react-dropdown-date";
import moment from 'moment';
import { AiFillCloseCircle } from 'react-icons/ai';
import Cookies from "js-cookie";
const ProductVariation = (props) => {
  const [allMedia, setAllMedia] = useState([]);
  const [show, setShow] = useState(false);
  const [updatedList, setUpdatedList] = useState([]);
  const [manageStocks, setManageStocks] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dateFormat = "YYYY/MM/DD";
  //Update the list based on the last state value of the varitation
  function UpdateList(e) {
    const { name, value } = e.target;

    var array = [...props.variations];

    array.forEach((element, i) => {
      if (name == "Quantity") {
        element["IsManageStock"] = true;
        element[name] = value;

        if (value == 0 || value === "") {
          var element = document.getElementById(name + i);
          element.classList.add("is-invalid");
          element.focus();
        } else {
          var element = document.getElementById(name + i);
          element.classList.remove("is-invalid");
        }
      } else if (name == "RegularPrice") {
        element[name] = value
        if (value == 0 || value === "") {
          var element = document.getElementById(name + i);
          element.classList.add("is-invalid");
          element.focus();
        } else {
          var element = document.getElementById(name + i);
          element.classList.remove("is-invalid");
        }
      }

      else {
        element[name] = value
      }


    });
    setUpdatedList(array);
  }

  //Update All Variations Description
  function UpdateAllDescriptions(data) {

    var array = [...props.variations];
    array.forEach(element => {
      element.Description = data;
    });
    setUpdatedList(array);
  }
  //Handles the button ok in the set value of items in pop up
  function handleOk() {
    console.log("testing",updatedList);

    props.setVariations(updatedList);
    setShow(false);
  }

  //Handles the button cancel in the set value of items in pop up
  function handleCancel() {
    setUpdatedList([]);
    setShow(false);
  }

  const ValidateVariationPrice = (price) => {
    var counter = 0;
    // Make sure that the price is available and is not below or equal to zero
    if (props.variations.length > 0) {
      props.variations.forEach((item) => {
        if (item.RegularPrice == 0 || item.RegularPrice == "") {
          var element = document.getElementById("RegularPrice" + counter);
          element.classList.add("is-invalid");
          element.focus();
          price = true;
        }
        counter++;
      });
    }

    return price;
  };

  //Make sure that the quantity is not zero
  const ValidateVariationQuantity = (quantity) => {
    var counter = 0;
    if (props.variations.length > 0) {
      props.variations.forEach((item) => {
        if (item.Quantity == 0 || item.Quantity == "") {
          var element = document.getElementById("Quantity" + counter);
          element.classList.add("is-invalid");
          element.focus();
          quantity = true;
        }
        counter++;
      });
    }
    return quantity;
  };

  const ValidateVariationImages = (images) => {
    var images = false;
    props.variations.forEach((element) => {
      if (
        element.ImageId0 == 0 && element.ImageId1 == 0 && element.ImageId2 == 0 && element.ImageId3 == 0 && element.ImageId4 == 0
      ) {
        images = true;
      }
    });
    return images;
  };

  //Check if all attributes are selected
  const ValidateVariationAttribute = (attribute) => {
    if (props.variations.length > 0) {
      props.variations.forEach((item) => {
        if (item.AssignedAttributes.length < props.savedAssigned.length) {
          attribute = true;
        } else {
          item.AssignedAttributes.forEach((element) => {
            if (element.AttributeValueId == 0) {
              attribute = true;
            }
          });
        }
      });
    }
    return attribute;
  };
  //Check variations attribute values are duplicated
  const ValidateVariationDuplicateAttributes = () => {
    var sortedList = [];
    props.variations.forEach((element, i) => {
      sortedList.push(
        element.AssignedAttributes.sort((a, b) => a.AttributeId - b.AttributeId)
      );
    });

    var isExist = false;
    sortedList.map((element, i) => {
      sortedList.map((subElement, index) => {
        if (i !== index) {
          if (JSON.stringify(element) == JSON.stringify(subElement)) {
            isExist = true;
          }
        }
      });
    });
    return isExist;
  };

  //Check default variation is duplicated
  const ValidateDefaultVariation = (checkDefaultVariation) => {
    if (props.variations.length > 0) {
      var count = 0;
      props.variations.forEach((item) => {
        if (item.IsDefaultVariation == true && item.IsPublished == true) {
          count = count + 1;
        }
      });
    }
    if (count > 1) {
      checkDefaultVariation = true;
    }
    return checkDefaultVariation;
  };

  // handle toast
  const HandleToast = (
    price,
    quantity,
    attribute,
    images,
    saved,
    checkAttributes,
    checkDefaultVariation
  ) => {
    if (price == true) {
      saved = false;
      toast.error("Please Choose A Price");
    }
    if (quantity == true) {
      saved = false;
      toast.error("Please Choose A Quantity");
    }
    if (attribute == true) {
      saved = false;
      toast.error("Please Choose All Attributes");
    }
    if (images == true) {
      saved = false;
      toast.error("Please choose at least two images for each variation");
    }
    //Akheran Zabatat
    if (checkAttributes == true) {
      saved = false;
      toast.error(
        "You CAN'T have two variations with the same Attribute Values "
      );
    }
    if (checkDefaultVariation == true) {
      saved = false;
      toast.error("You cannot have two published default variations");
    }
    if (saved === true) {
      props.setSavedVariations(props.variations);
      toast.success("Variation saved");
    }
  };

  //save variations

  const SaveVariations = () => {
    var saved = true;
    var price = false,
      quantity = false,
      images = false,
      attribute = false,
      checkAttributes = false,
      checkDefaultVariation = false;

    price = ValidateVariationPrice(price);
    quantity = ValidateVariationQuantity(price);
    images = ValidateVariationImages(images);
    attribute = ValidateVariationAttribute(attribute);
    checkAttributes = ValidateVariationDuplicateAttributes();
    checkDefaultVariation = ValidateDefaultVariation(checkDefaultVariation);

    HandleToast(
      price,
      quantity,
      attribute,
      images,
      saved,
      checkAttributes,
      checkDefaultVariation,
      checkDefaultVariation
    );
  };

  // Add new Variation
  function handleAddVariation(index) {
    var array = [...props.variations];
    if (props.variationType == 1) {
      if (array.length == 0) {
        var newVariation = {
          ProductVariationId: 0,
          Sku: props.Sku + 1,
          Quantity: 0,
          LowStockThreshold: 0,
          Weight: 0,
          Length: 0,
          Width: 0,
          Height: 0,
          SalePrice: 0,
          RegularPrice: 0,
          ImageId0: 0,
          ImageId1: 0,
          ImageId2: 0,
          ImageId3: 0,
          ImageId4: 0,
          IsPublished: true,
          IsDefaultVariation: true,
          SaleStartDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
          SaleEndDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
          StockStatus: 1,
          AllowBackOrder: 1,
          Description: props.productDescription,
          IsSaleSchedule: false,
          IsManageStock: true,
          Image0: "",
          Image1: "",
          Image2: "",
          Image3: "",
          Image4: "",
          index: 0,
          AssignedAttributes: [],
        };
      } else {
        var newVariation = {
          ProductVariationId: 0,
          Sku: props.Sku + (array[array.length - 1].index + 2),
          Quantity: 0,
          LowStockThreshold: 0,
          Weight: 0,
          Length: 0,
          Width: 0,
          Height: 0,
          SalePrice: 0,
          RegularPrice: 0,
          ImageId0: 0,
          ImageId1: 0,
          ImageId2: 0,
          ImageId3: 0,
          ImageId4: 0,
          IsPublished: true,
          IsDefaultVariation: false,
          SaleStartDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
          SaleEndDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
          StockStatus: 1,
          AllowBackOrder: 1,
          Description: props.productDescription,
          IsSaleSchedule: false,
          IsManageStock: true,
          Image0: "",
          Image1: "",
          Image2: "",
          Image3: "",
          Image4: "",
          index: array[array.length - 1].index + 1,
          AssignedAttributes: [],
        };
      }
      array.push(newVariation);
    }
    if (props.variationType == 2) {
      var saved = [...props.savedAssigned];
      var attributes = 0;

      for (var i = 0; i < saved.length; i++) {
        if (saved.length == 1) {
          attributes = saved[i].AttributeValues.length;
        } else if (saved[i + 1] != undefined) {
          if (
            saved[i].AttributeValues.length != 1 ||
            saved[i + 1].AttributeValues.length != 1
          ) {
            attributes =
              saved[i].AttributeValues.length *
              saved[i + 1].AttributeValues.length;
          }
        }
      }
      for (var i = 0; i < attributes; i++) {
        if (array.length == 0) {
          if (i == 0) {
            var newVariation = {
              ProductVariationId: 0,
              Sku: props.Sku + 1,
              Quantity: 0,
              LowStockThreshold: 0,
              Weight: 0,
              Length: 0,
              Width: 0,
              Height: 0,
              SalePrice: 0,
              RegularPrice: 0,
              ImageId0: 0,
              ImageId1: 0,
              ImageId2: 0,
              ImageId3: 0,
              ImageId4: 0,
              IsPublished: true,
              IsDefaultVariation: true,
              SaleStartDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
              SaleEndDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
              StockStatus: 1,
              AllowBackOrder: 1,
              Description: props.productDescription,
              IsSaleSchedule: false,
              IsManageStock: true,
              Image0: "",
              Image1: "",
              Image2: "",
              Image3: "",
              Image4: "",
              index: 0,
              AssignedAttributes: [],
            };
          } else {
            var newVariation = {
              ProductVariationId: 0,
              Sku: props.Sku + 1,
              Quantity: 0,
              LowStockThreshold: 0,
              Weight: 0,
              Length: 0,
              Width: 0,
              Height: 0,
              SalePrice: 0,
              RegularPrice: 0,
              ImageId0: 0,
              ImageId1: 0,
              ImageId2: 0,
              ImageId3: 0,
              ImageId4: 0,
              IsPublished: true,
              IsDefaultVariation: false,
              SaleStartDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
              SaleEndDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
              StockStatus: 1,
              AllowBackOrder: 1,
              Description: props.productDescription,
              IsSaleSchedule: false,
              IsManageStock: true,
              Image0: "",
              Image1: "",
              Image2: "",
              Image3: "",
              Image4: "",
              index: 0,
              AssignedAttributes: [],
            };
          }
        } else {
          if (i == 0) {
            var newVariation = {
              ProductVariationId: 0,
              Sku: props.Sku + (array[array.length - 1].index + 2),
              Quantity: 0,
              LowStockThreshold: 0,
              Weight: 0,
              Length: 0,
              Width: 0,
              Height: 0,
              SalePrice: 0,
              RegularPrice: 0,
              ImageId0: 0,
              ImageId1: 0,
              ImageId2: 0,
              ImageId3: 0,
              ImageId4: 0,
              IsPublished: true,
              IsDefaultVariation: true,
              SaleStartDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
              SaleEndDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
              StockStatus: 1,
              AllowBackOrder: 1,
              Description: props.productDescription,
              IsSaleSchedule: false,
              IsManageStock: true,
              Image0: "",
              Image1: "",
              Image2: "",
              Image3: "",
              Image4: "",
              index: array[array.length - 1].index + 1,
              AssignedAttributes: [],
            };
          } else {
            var newVariation = {
              ProductVariationId: 0,
              Sku: props.Sku + (array[array.length - 1].index + 2),
              Quantity: 0,
              LowStockThreshold: 0,
              Weight: 0,
              Length: 0,
              Width: 0,
              Height: 0,
              SalePrice: 0,
              RegularPrice: 0,
              ImageId0: 0,
              ImageId1: 0,
              ImageId2: 0,
              ImageId3: 0,
              ImageId4: 0,
              IsPublished: true,
              IsDefaultVariation: false,
              SaleStartDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
              SaleEndDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
              StockStatus: 1,
              AllowBackOrder: 1,
              Description: props.productDescription,
              IsSaleSchedule: false,
              IsManageStock: true,
              Image0: "",
              Image1: "",
              Image2: "",
              Image3: "",
              Image4: "",
              index: array[array.length - 1].index + 1,
              AssignedAttributes: [],
            };
          }
        }

        array.push(newVariation);
      }
    }
    if (props.variationType == 3) {
      array = [];
    }
    if (props.variationType > 3 && props.variationType < 12) {
      setShow(true);
    }
    if (props.variationType == 12) {
      for (var i = 0; i < array.length; i++) {
        array[i]["StockStatus"] = 1;
      }
    }
    if (props.variationType == 13) {
      for (var i = 0; i < array.length; i++) {
        array[i]["StockStatus"] = 2;
      }
    }
    if (props.variationType == 14) {
      for (var i = 0; i < array.length; i++) {
        array[i]["StockStatus"] = 3;
      }
    }
    props.setVariations(array);
  }
  // manage sale schedule
  const SetVariationScheduleStatus = (index) => {
    if (props.variationScheduleStatus !== -1) {
      props.setVariationScheduleStatus(-1);
    } else {
      props.setVariationScheduleStatus(index);
    }
  };

  //Handle variation change
  const handleVariationUpdate = (e, variationIndex) => {
    const { name, value } = e.target;
    var array = [...props.variations];
    array.forEach((element, i) => {
      if (i == variationIndex && name == "Quantity") {
        if (value == 0 || value === "") {
          var element = document.getElementById(name + i);
          element.classList.add("is-invalid");
          element.focus();
        } else {
          var element = document.getElementById(name + i);
          element.classList.remove("is-invalid");
        }
      }
      if (i == variationIndex && name == "RegularPrice") {
        if (value == 0 || value === "") {
          var element = document.getElementById(name + i);
          element.classList.add("is-invalid");
          element.focus();
        } else {
          var element = document.getElementById(name + i);
          element.classList.remove("is-invalid");
        }
      }
      // if (i == variationIndex && name == "SalePrice") {
      //   if (value == 0 || value === "" || Math.sign(value)) {
      //     var element = document.getElementById(name + i);
      //     element.classList.add("is-invalid");
      //     element.focus();
      //   } else {
      //     var element = document.getElementById(name + i);
      //     element.classList.remove("is-invalid");
      //   }
      // }
    });


    let variationToUpdate = array[variationIndex];
    delete array[variationIndex];
 
    if (name === "IsManageStock") {
      variationToUpdate[name] = e.target.checked;
    } else {
      if (name === "IsPublished") {
        variationToUpdate[name] = e.target.checked;
      } else {
        if (name === "IsDefaultVariation") {
          variationToUpdate[name] = e.target.checked;
        } else {
          if (name === "SaleEndDate" || name === "SaleStartDate") {
            if (value !== "") {
              variationToUpdate[name] = value;
              variationToUpdate["IsSaleSchedule"] = true;
            } else {
              variationToUpdate[name] = "";
              variationToUpdate["IsSaleSchedule"] = false;
            }
          } else {
            if(name === "SalePrice"){
              if(value == 0 || value == "" || Math.sign(value) === -1 || value === null){
                variationToUpdate[name] = 0;
        
              } else {
                variationToUpdate[name] = e.target.value;
              }
            } else {

              variationToUpdate[name] = value;
            }
          }
        }
      }
    }
    array[variationIndex] = variationToUpdate;
    props.setVariations(array);
  };

  //handle variation SaleStartDate

  const handleVariationSaleStartDate = (date, index) => {
    var array = [...props.variations];
    array.forEach(element => {
      element.SaleStartDate = date
    });
    props.setVariations(array)
  }

  const handleVariationSaleEndDate = (date, index) => {
    var array = [...props.variations];
    array.forEach(element => {
      element.SaleEndDate = date
    });
    props.setVariations(array)
  }
  //hanlde description change
  const handleDescriptionUpdate = (data, variationIndex) => {
    var array = [...props.variations];
    let variationToUpdate = array[variationIndex];
    delete array[variationIndex];
    variationToUpdate["Description"] = data;
    array[variationIndex] = variationToUpdate;
    props.setVariations(array);
  }

  // Manage All Stocks
  function handleManageAllStock(e) {
    setManageStocks(e.target.checked);
    var array = [...props.variations];
    array.forEach((element) => {
      element.IsManageStock = e.target.checked;
    });
    props.setVariations(array);
  }
  // Delete Variation
  const DeleteVariation = async (i) => {

    const array = [...props.variations];

    var media = [...allMedia];
    // var c = array.indexOf()
    if (array[i].ProductVariationId == 0) {
      array.splice(i, 1);


    }
    else {
      const token = Cookies.get("UserToken");
      let response = await fetch(Variables.API_URL + "ProductVariation/DeleteProductVariation/" + array[i].ProductVariationId, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      });
      let result = await response.json();
      if (response.status === 200) {
        GetAllVariations(props.ProductID);

      }
      else {
        toast.error(result);
      }
    }
    media.forEach((element, index) => {
      if (element.index == i) {
        media.splice(index, 1);
      }
    });
    setAllMedia(media);
    props.setVariations(array);
  };

  const handleCollapseOpen = (index) => {
    if (props.collapsOpen === -1) {
      props.setCollapsOpen(index);
    } else {
      props.setCollapsOpen(-1);
    }
  };

  const handleAttributes = (
    AttributeId,
    AttributeValueId,
    index,
    ind,
    AttributeName
  ) => {
    debugger;
    var array = [...props.variations];
    var exist = false;
    var attribute = {
      AttributeId: AttributeId,
      AttributeValueId: parseInt(AttributeValueId),
      AttributeName: AttributeName,
    };

    for (var i = 0; i < array.length; i++) {
      if (array[i].index === index) {
        if (AttributeValueId == 0) {
          var element = document.getElementById(
            "Variation" + ind + "Value" + index
          );
          element.classList.add("is-invalid");
          element.focus();
        } else {
          var element = document.getElementById(
            "Variation" + ind + "Value" + index
          );
          element.classList.remove("is-invalid");
        }

        if (array[i].AssignedAttributes.length == 0) {
          array[i].AssignedAttributes.push(attribute);
        } else {
          for (var j = 0; j < array[i].AssignedAttributes.length; j++) {
            if (array[i].AssignedAttributes[j].AttributeId == AttributeId) {
              exist = true;
              delete array[i].AssignedAttributes[j];
              array[i].AssignedAttributes[j] = attribute;
            }
          }
          if (exist == false) {
            array[i].AssignedAttributes.push(attribute);
          }
        }
      }
    }
    var ImageId0 = 0;
    var ImageId1 = 0;
    var ImageId2 = 0;
    var ImageId3 = 0;
    var ImageId4 = 0;
    var Image0 = "";
    var Image1 = "";
    var Image2 = "";
    var Image3 = "";
    var Image4 = "";
    array.forEach((element) => {
      element.AssignedAttributes.forEach((item) => {
        if (
          item.AttributeName == "Color" &&
          item.AttributeValueId == AttributeValueId &&
          element.index != index
        ) {
          ImageId0 = element.ImageId0;
          ImageId1 = element.ImageId1;
          ImageId2 = element.ImageId2;
          ImageId3 = element.ImageId3;
          ImageId4 = element.ImageId4;
          Image0 = element.Image0;
          Image1 = element.Image1;
          Image2 = element.Image2;
          Image3 = element.Image3;
          Image4 = element.Image4;
        }
      });

      if (element.index == index && AttributeName == "Color") {
        element.ImageId0 = ImageId0;
        element.ImageId1 = ImageId1;
        element.ImageId2 = ImageId2;
        element.ImageId3 = ImageId3;
        element.ImageId4 = ImageId4;
        element.Image0 = Image0;
        element.Image1 = Image1;
        element.Image2 = Image2;
        element.Image3 = Image3;
        element.Image4 = Image4;
        var selected = [...allMedia];
        selected.forEach((element, i) => {
          if (element.index == index) {
            selected.splice(i);
          }
        });

        if (ImageId0 != 0 && Image0 != "") {
          selected.push({ MediaId: ImageId0, Image: Image0, index: index });
        }
        if (ImageId1 != 0 && Image1 != "") {
          selected.push({ MediaId: ImageId1, Image: Image1, index: index });
        }
        if (ImageId2 != 0 && Image2 != "") {
          selected.push({ MediaId: ImageId2, Image: Image2, index: index });
        }
        if (ImageId3 != 0 && Image3 != "") {
          selected.push({ MediaId: ImageId3, Image: Image3, index: index });
        }
        if ((ImageId4 = !0 && Image4 != "")) {
          selected.push({ MediaId: ImageId4, Image: Image4, index: index });
        }


        setAllMedia(selected);
      }
    });

    props.setVariations(array);
  };

  // get AllVariations
  async function GetAllVariations(ProductID) {
    const URL =
      Variables.API_URL + "ProductVariation/GetProductVariations/" + ProductID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    props.setVariations(listItems);
    props.setSavedVariations(listItems);
    var media = [];
    var obj0 = {
      MediaId: 0,
      Image0: "",
      index: 0,
    };
    var obj1 = {
      MediaId: 0,
      Image0: "",
      index: 0,
    };
    var obj2 = {
      MediaId: 0,
      Image0: "",
      index: 0,
    };
    var obj3 = {
      MediaId: 0,
      Image0: "",
      index: 0,
    };
    var obj4 = {
      MediaId: 0,
      Image0: "",
      index: 0,
    };
    for (var i = 0; i < listItems.length; i++) {
      obj0 = {
        MediaId: listItems[i].ImageId0,
        Image: listItems[i].Image0,
        index: listItems[i].index,
      };

      obj1 = {
        MediaId: listItems[i].ImageId1,
        Image: listItems[i].Image1,
        index: listItems[i].index,
      };
      obj2 = {
        MediaId: listItems[i].ImageId2,
        Image: listItems[i].Image2,
        index: listItems[i].index,
      };
      obj3 = {
        MediaId: listItems[i].ImageId3,
        Image: listItems[i].Image3,
        index: listItems[i].index,
      };
      obj4 = {
        MediaId: listItems[i].ImageId4,
        Image: listItems[i].Image4,
        index: listItems[i].index,
      };

      if (obj0.MediaId != 0) {
        media.push(obj0);
      }
      if (obj1.MediaId != 0) {
        media.push(obj1);
      }
      if (obj2.MediaId != 0) {
        media.push(obj2);
      }
      if (obj3.MediaId != 0) {
        media.push(obj3);
      }
      if (obj4.MediaId != 0) {
        media.push(obj4);
      }
    }

    var allMedia = [];
    for (var i = 0; i < media.length; i++) {
      allMedia.push(media[i]);
    }
    setAllMedia(allMedia);
  }

  const RemoveImage = async (MediaId, ProductVariationId) => {
    // e.preventDefault();
    setIsLoading(true);
    const token = Cookies.get("UserToken");
    fetch(Variables.API_URL + "ProductVariation/DeleteImageFromVariation/" + ProductVariationId + "/" + MediaId, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Variables.API_URL,
      }
    }).then((response => {
      if (response.ok) {
        const newAllMeida  = allMedia.filter((item) => item.MediaId != MediaId);
        setAllMedia(newAllMeida);
        toast.success("Image Removed");
      }
    }))
    setIsLoading(false);





    
  }


  useEffect(() => {
    if (props.ProductID != 0) {
      GetAllVariations(props.ProductID);

    }
  }, [props.ProductID, isLoading]);

  return (
    <>
      <div className="row shipping item">
        <div className="col-lg-12">
          {props.savedAssigned.length == 0 ? (
            <div className="alert alert-danger">
              Before you can add a variation you need to add some variation
              attributes on the Attributes tab.
              <br />
              <button
                className="btn btn-primary"
                onClick={() =>
                  window.open(
                    "https://woocommerce.com/document/variable-product/"
                  )
                }
              >
                {" "}
                Learn More
              </button>
            </div>
          ) : null}
        </div>
      </div>
      {props.savedAssigned.length != 0 ? (
        <>
          <div className="row mt-3">
            <div className="col-6 col-lg-6">
              <select
                className="form-control"
                value={props.variationType}
                onChange={(e) => {
                  props.setVariationType(e.target.value);
                }}
              >
                <option value={1} >
                  Add Variation
                </option>
                <option value={2} >
                  Create Variation For All Attribute
                </option>
                {props.variations.length != 0 ? (
                  <>
                    {" "}
                    <option value={3} >
                      Delete All Variations
                    </option>
                    <option
                      disabled
                      style={{ color: "blue", fontSize: "large" }}
                    >
                      Pricing
                    </option>
                    <option value={4} >
                      Set Regular Prices
                    </option>
                    <option value={5} >
                      Set Sale Prices
                    </option>
                    <option
                      disabled
                      style={{ color: "blue", fontSize: "large" }}
                    >
                      Shipping
                    </option>
                    <option value={6} >
                      Length
                    </option>
                    <option value={7} >
                      {" "}
                      Weight
                    </option>
                    <option value={8} >
                      Height
                    </option>
                    <option value={9}>
                      {" "}
                      Width
                    </option>
                    <option
                      disabled
                      style={{ color: "blue", fontSize: "large" }}
                    >
                      Description
                    </option>
                    <option value={11} >
                      {" "}
                      Description
                    </option>
                    <option
                      disabled
                      style={{ color: "blue", fontSize: "large" }}
                    >
                      Inventory
                    </option>
                    <option value={10} >
                      {" "}
                      Stock
                    </option>
                    <option value={12} >
                      Set Status - In Stock
                    </option>
                    <option value={13} >
                      {" "}
                      set Status - Out Of Stock{" "}
                    </option>
                    <option value={14} >
                      {" "}
                      set Status - On Backorder{" "}
                    </option>
                  </>
                ) : null}
              </select>
            </div>

            <div className="col-1 col-lg-1">
              <button className="btn btn-primary" onClick={handleAddVariation}>
                Go
              </button>
              {props.variationType == 4 ? (
                <Modal show={show} onHide={() => setShow(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Enter a value</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <input
                      type="number"
                      name="RegularPrice"
                      onChange={(e) => UpdateList(e)}
                      className="form-control"
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-primary"
                      style={{ width: "70px" }}
                      onClick={handleOk}
                    >
                      {" "}
                      Ok
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : null}
              {props.variationType == 5 ? (
                <Modal show={show} onHide={() => setShow(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Enter a value</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <input
                      type="number"
                      name="SalePrice"
                      onChange={(e) => UpdateList(e)}
                      className="form-control"
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-primary"
                      style={{ width: "70px" }}
                      onClick={handleOk}
                    >
                      {" "}
                      Ok
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : null}
              {props.variationType == 6 ? (
                <Modal show={show} onHide={() => setShow(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Enter a value</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <input
                      type="number"
                      name="Length"
                      onChange={(e) => UpdateList(e)}
                      className="form-control"
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-primary"
                      style={{ width: "70px" }}
                      onClick={handleOk}
                    >
                      {" "}
                      Ok
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : null}
              {props.variationType == 7 ? (
                <Modal show={show} onHide={() => setShow(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Enter a value</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <input
                      type="number"
                      name="Weight"
                      onChange={(e) => UpdateList(e)}
                      className="form-control"
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-primary"
                      style={{ width: "70px" }}
                      onClick={handleOk}
                    >
                      {" "}
                      Ok
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : null}
              {props.variationType == 8 ? (
                <Modal show={show} onHide={() => setShow(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Enter a value</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <input
                      type="number"
                      name="Height"
                      onChange={(e) => UpdateList(e)}
                      className="form-control"
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-primary"
                      style={{ width: "70px" }}
                      onClick={handleOk}
                    >
                      {" "}
                      Ok
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : null}
              {props.variationType == 9 ? (
                <Modal show={show} onHide={() => setShow(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Enter a value</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <input
                      type="number"
                      name="Width"
                      onChange={(e) => UpdateList(e)}
                      className="form-control"
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-primary"
                      style={{ width: "70px" }}
                      onClick={handleOk}
                    >
                      {" "}
                      Ok
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : null}
              {props.variationType == 10 ? (
                <Modal show={show} onHide={() => setShow(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Enter a value</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <input
                      type="number"
                      name="Quantity"
                      onChange={(e) => UpdateList(e)}
                      className="form-control"
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-primary"
                      style={{ width: "70px" }}
                      onClick={handleOk}
                    >
                      {" "}
                      Ok
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : null}
              {props.variationType == 11 ? (
                <Modal show={show} onHide={() => setShow(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Enter Description</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <CKEditor
                      editor={ClassicEditor}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        UpdateAllDescriptions(data)


                      }}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-primary"
                      style={{ width: "70px" }}
                      onClick={handleOk}
                    >
                      {" "}
                      Ok
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : null}
            </div>
            <div className="col-3 col-lg-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={manageStocks}
                  onChange={(e) => handleManageAllStock(e)}
                  checked={props.variations.every(val => val.IsManageStock === props.variations[0].IsManageStock)}
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Manage All Stocks
                </label>
              </div>
            </div>
          </div>
          <hr />

          {props.variations.map((variation, index) => (

            <div className="row mt-3" key={index} id={"Variation" + index}>
              <div className="col-1 col-lg-1" style={{ marginTop: "5px" }}>
                {props.Sku + (index + 1)}
              </div>
              {props.savedAssigned.map((s, i) => {
                return (
                  <div className="col-4 col-lg-2" key={i}>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        handleAttributes(
                          s.AttributeId,
                          e.target.value,
                          index,
                          i,
                          s.AttributeName
                        )
                      }
                      value={
                        props.variations[index].AssignedAttributes.filter(
                          (value) => value.AttributeId === s.AttributeId
                        ).length !== 0
                          ? props.variations[index].AssignedAttributes.filter(
                            (value) => value.AttributeId === s.AttributeId
                          )[0].AttributeValueId
                          : 0
                      }
                      id={"Variation" + i + "Value" + index}
                    >
                      <option value={0}> Select</option>
                      {s.AttributeValues.map((v, y) => {
                        return (


                          <option value={v.AttributeValueId} key={y}>
                            {v.AttributeValueName}{" "}
                          </option>


                        );
                      })}
                    </select>
                  </div>
                );
              })}
              <div
                className="col-3 col-lg-3 "

                style={{
                  float: "right",
                  textAlign: "center",
                }}
              >
                <button
                  className="btn btn-danger"
                  style={{
                    marginRight: "10px",
                  }}
                  onClick={() => DeleteVariation(index)}
                >
                  <BiTrash />
                </button>
                <Button
                  onClick={() => handleCollapseOpen(index)}
                  aria-controls="example-collapse-text"
                  aria-expanded={props.collapsOpen === -1 ? false : true}
                >
                  <BiEdit />
                </Button>
              </div>
              <Collapse
                in={props.collapsOpen === index ? true : false}
              >
                <div id="example-collapse-text">
                  <div className="card mt-4">
                    <div className="card-header">
                      <b> Manage Variation {index}</b>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              name="IsPublished"
                              id="IsPublished"
                              className="form-check-input"
                              checked={variation.IsPublished}
                              onChange={(e) => handleVariationUpdate(e, index)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="IsPublished"
                            >
                              Publish
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              name="IsManageStock"
                              id="IsManageStock"
                              className="form-check-input"
                              onChange={(e) => handleVariationUpdate(e, index)}
                              checked={variation.IsManageStock}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="IsManageStock"
                            >
                              Manage Stock
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              name="IsDefaultVariation"
                              id="IsDefaultVariation"
                              className="form-check-input"
                              onChange={(e) => handleVariationUpdate(e, index)}
                              checked={variation.IsDefaultVariation}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="IsDefaultVariation"
                            >
                              Default Variation
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <VariationGallery
                            variations={props.variations}
                            setVariations={props.setVariations}
                            index={index}
                            allMedia={allMedia}
                            setAllMedia={setAllMedia}
                          />
                          <div className="row">
                            {allMedia.length != 0 ? (
                              <>
                                {allMedia.map((m, ix) => {
                                  return (
                                    <>
                                      {m.index == index ? (
                                        <div className="col-3" key={ix + 1}>
                                          {isLoading ? (
                                            <div className="spinner-container">
                                              <div className="loading-spinner">blabla</div>
                                            </div>
                                          ) : (
                                            <div>
                                              <div style={{ position: 'absolute', cursor: 'pointer' }}>
                                                <AiFillCloseCircle onClick={() => RemoveImage(m.MediaId, variation.ProductVariationId)} />
                                              </div>
                                              <img
                                                src={
                                                  Variables.API_PUBLISHED + m.Image
                                                }
                                                className="form-control"
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                }}
                                              />
                                            </div>
                                          )}

                                        </div>
                                      ) : null}
                                    </>
                                  );
                                })}
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                          <label className="mt-3">SKU</label>
                          <input
                            className="form-control"
                            id="Sku"
                            name="Sku"
                            onChange={(e) => handleVariationUpdate(e, index)}
                            value={variation.Sku}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="mt-3">Regular price</label>
                          <input
                            className="form-control"
                            name={"RegularPrice"}
                            id={"RegularPrice" + index}
                            type="number"
                            onChange={(e) => handleVariationUpdate(e, index)}
                            value={variation.RegularPrice}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="mt-3">Sale Price</label>
                          {"   "}
                          <a
                            className="btn btn-link"
                            style={{ marginBottom: "-5px", marginTop: "-15px" }}
                            onClick={() => SetVariationScheduleStatus(index)}
                          >
                            Schedule
                          </a>
                          <input
                            className="form-control"
                            id={"SalePrice" + index}
                            name="SalePrice"
                            type="number"
                            onChange={(e) => handleVariationUpdate(e, index)}
                            value={variation.SalePrice}
                          />
                        </div>

                        {props.variationScheduleStatus === index && (
                          <div className="col-md-6">
                            <label className="mt-3">Start Date({moment(variation.SaleStartDate).format('MM-DD-YYYY')})</label>
                            {/* <input
                              className="form-control"
                              placeholder="From .... YYYY-MM-DD"
                              type="datetime-local"
                              id="SaleStartDate"
                              name="SaleStartDate"
                              defaultValue={variation.SaleStartDate || new Date()}
                              onChange={(e) => handleVariationUpdate(e, index)}
                            /> */}
                            {/* {console.log("date",moment(Date().toLocaleString()).format('MM-DD-YYYY'))} */}
                            {/* {console.log("datetime now",moment((new Date().getFullYear()) + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()).format('MM-DD-YYYY') + "T" + moment(new Date().getTime()).format('HH:mm:ss'))} */}
                            <DropdownDate
                              startDate={
                                // optional, if not provided 1900-01-01 is startDate
                                //variation.SaleStartDate // 'yyyy-mm-dd' format only
                                // moment(Date().toLocaleString()).format('MM-DD-YYYY')
                                // moment(Date().toLocaleString()).format('YYYY-MM-DD')
                                (new Date().getFullYear()) + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()
                              }
                              endDate={
                                // optional, if not provided current date is endDate
                                (new Date().getFullYear() + 100) + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()// 'yyyy-mm-dd' format only
                              }
                              selectedDate={
                                // optional
                                variation.SaleStartDate // 'yyyy-mm-dd' format only
                              }
                              
                              order={[
                                // optional
                                DropdownComponent.day, // Order of the dropdowns
                                DropdownComponent.month,
                                DropdownComponent.year,
                              ]}
                              onDateChange={(date) => {
                                // optional
                                handleVariationSaleStartDate(date, index)
                              }}
                              ids={
                                // optional
                                {
                                  year: "select-year",
                                  month: "select-month",
                                  day: "select-day",
                                }
                              }
                              names={
                                // optional
                                {
                                  year: "year",
                                  month: "month",
                                  day: "day",
                                }
                              }
                              classes={
                                // optional
                                {
                                  dateContainer: "InputBox",
                                  yearContainer: "InputBox",
                                  monthContainer: "InputBox",
                                  dayContainer: "InputBox",
                                  year: "InputBox InputBox",
                                  month: "InputBox InputBox",
                                  day: "InputBox InputBox",
                                  yearOptions: "InputBox",
                                  monthOptions: "InputBox",
                                  dayOptions: "InputBox",
                                }
                              }
                              defaultValues={
                                // optional
                                {
                                  year: "Year",
                                  month: "Month",
                                  day: "Day",
                                }
                              }
                              options={
                                // optional
                                {
                                  yearReverse: false, // false by default
                                  monthShort: true, // false by default
                                  monthCaps: true, // false by default
                                }
                              }
                            />
                          </div>
                        )}


                        {props.variationScheduleStatus === index && (
                          <div className="col-md-6">
                            <label className="mt-3">End Date {moment(variation.SaleEndDate).format('MM-DD-YYYY')}</label>
                            {/* <input
                              className="form-control"
                              placeholder="From .... YYYY-MM-DD"
                              type="datetime-local"
                              id="SaleEndDate"
                              name="SaleEndDate"
                              defaultValue={moment(variation.SaleEndDate, dateFormat)}
                              onChange={(e) => handleVariationUpdate(e, index)}
                            /> */}
                            <DropdownDate
                              startDate={
                                // optional, if not provided 1900-01-01 is startDate
                                // variation.SaleEndDate
                                (new Date().getFullYear()) + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()
                              }
                              endDate={
                                // optional, if not provided current date is endDate
                                (new Date().getFullYear() + 100) + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()// 'yyyy-mm-dd' format only
                              }
                              selectedDate={
                                // optional
                                variation.SaleEndDate // 'yyyy-mm-dd' format only
                              }
                              order={[
                                // optional
                                DropdownComponent.day, // Order of the dropdowns
                                DropdownComponent.month,
                                DropdownComponent.year,
                              ]}
                              onDateChange={(date) => {
                                // optional

                                handleVariationSaleEndDate(date, index)
                              }}
                              ids={
                                // optional
                                {
                                  year: "select-year",
                                  month: "select-month",
                                  day: "select-day",
                                }
                              }
                              names={
                                // optional
                                {
                                  year: "year",
                                  month: "month",
                                  day: "day",
                                }
                              }
                              classes={
                                // optional
                                {
                                  dateContainer: "InputBox",
                                  yearContainer: "InputBox",
                                  monthContainer: "InputBox",
                                  dayContainer: "InputBox",
                                  year: "InputBox InputBox",
                                  month: "InputBox InputBox",
                                  day: "InputBox InputBox",
                                  yearOptions: "InputBox",
                                  monthOptions: "InputBox",
                                  dayOptions: "InputBox",
                                }
                              }
                              defaultValues={
                                // optional
                                {
                                  year: "Year",
                                  month: "Month",
                                  day: "Day",
                                }
                              }
                              options={
                                // optional
                                {
                                  yearReverse: false, // false by default
                                  monthShort: true, // false by default
                                  monthCaps: true, // false by default
                                }
                              }
                            />
                          </div>
                        )}
                        {variation.IsManageStock === true ? (
                          <>
                            <div className="col-md-6">
                              <label className="mt-3">Stock quantity</label>
                              <input
                                className="form-control"
                                id={"Quantity" + index}
                                name={"Quantity"}
                                type="number"
                                onChange={(e) =>
                                  handleVariationUpdate(e, index)
                                }
                                value={variation.Quantity}
                              />
                            </div>

                            <div className="col-md-6">
                              <label className="mt-3">Allow backorders?</label>
                              <select
                                className="form-control"
                                value={variation.AllowBackOrder}
                                id="AllowBackOrder"
                                name="AllowBackOrder"
                                onChange={(e) =>
                                  handleVariationUpdate(e, index)
                                }
                              >
                                <option value={0}
                                >
                                  Select
                                </option>
                                <option value={1} >
                                  Do Not Allow
                                </option>
                                <option value={2} >
                                  Allow, but notify customer
                                </option>
                                <option value={3} >
                                  Allow
                                </option>
                              </select>
                            </div>
                          </>
                        ) : null}
                        {/* {props.ProductID != 0 && (
                          <div className="col-md-12">
                            <label className="mt-3">Remaining Quantity</label>
                            <input
                              className="form-control"
                              id="RemaningQuantity"
                              name="RemaningQuantity"
                              type="number"
                              value={variation.RemainingQuantity}
                              disabled={true}
                            />
                          </div>
                        )} */}

                        <div className="col-md-12">
                          <label className="mt-3">Remaining Quantity</label>
                          <input
                            className="form-control"
                            id={"RemainingQuantity"+index}
                            name="RemainingQuantity"
                            type="number"
                            onChange={(e) => handleVariationUpdate(e, index)}
                            value={variation.RemainingQuantity}
                          />
                        </div>
                        <div className="col-md-12">
                          <label className="mt-3">Low stock threshold</label>
                          <input
                            className="form-control"
                            id="LowStockThreshold"
                            name="LowStockThreshold"
                            type="number"
                            onChange={(e) => handleVariationUpdate(e, index)}
                            value={variation.LowStockThreshold}
                          />
                        </div>

                        <div className="col-md-6">
                          <label className="mt-3">Stock Status</label>
                          <select
                            className="form-control"
                            value={variation.StockStatus}
                            id="StockStatus"
                            name="StockStatus"
                            onChange={(e) => handleVariationUpdate(e, index)}
                          >
                            <option value={0}>
                              Select
                            </option>
                            <option value={1} >
                              In Stock
                            </option>
                            <option value={2}>
                              Out of Stock
                            </option>
                            <option value={3} >
                              On Backorder
                            </option>
                          </select>
                        </div>

                        <div className="col-md-6">
                          <label className="mt-3">Weight (kg)</label>
                          <input
                            className="form-control"
                            placeholder="Weight (kg)"
                            type="number"
                            id="Weight"
                            name="Weight"
                            value={variation.Weight}
                            onChange={(e) => handleVariationUpdate(e, index)}
                          />
                        </div>

                        <div className="col-md-6">
                          <label className="mt-3">
                            Dimensions (L×W×H) (cm)
                          </label>
                          <div className="row">
                            <div className="col-md-4">
                              <input
                                className="form-control"
                                placeholder="Length"
                                type="number"
                                id="Length"
                                name="Length"
                                value={variation.Length}
                                onChange={(e) =>
                                  handleVariationUpdate(e, index)
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <input
                                className="form-control"
                                placeholder="Width"
                                type="number"
                                id="Width"
                                name="Width"
                                value={variation.Width}
                                onChange={(e) =>
                                  handleVariationUpdate(e, index)
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <input
                                className="form-control"
                                placeholder="Height"
                                type="number"
                                id="Height"
                                name="Height"
                                value={variation.Height}
                                onChange={(e) =>
                                  handleVariationUpdate(e, index)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <label className="mt-3">Description</label>
                          {/* <CKEditor
                            editor={ClassicEditor}
                            data={variation.Description}
                            value={variation.Description}
                            name="Description"
                            onChange={(e, editor) => {
                              handleVariationUpdate(e, index);
                            }}
                          /> */}
                          {/* <textarea name="Description"   value={variation.Description} onChange={(e) => {
                              handleVariationUpdate(e, index);
                            }} className="form-control"/> */}

                          <CKEditor
                            editor={ClassicEditor}
                            data={variation.Description}
                            value={variation.Description}
                            name="Descrption"

                            onChange={(event, editor) => {
                              const data = editor.getData();
                              handleDescriptionUpdate(data, index);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
              <div className="col-12">
                <hr />
              </div>
            </div>
          ))}

          {props.variations.length !== 0 ? (
            <div className="row">
              <div className="col-12 text-end ">
                <button className="btn btn-primary " onClick={SaveVariations}>
                  Save Changes
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : null}
    </>
  );
};

export default ProductVariation;
