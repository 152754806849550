import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom"
import DataTable from 'react-data-table-component';
import { Variables } from "../../Variations/Variations"
import { BiTrash, BiEdit, BiShowAlt, BiHide } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import Util from "../../Classes/Util";
import { AiOutlineClose, AiOutlineCheck, AiOutlineRight } from 'react-icons/ai';
import { Button, Modal } from "react-bootstrap";
import Cookies from "js-cookie";

const ListBrands = () => {
  const [listBrands, setBrands] = useState([]);
  const [filterText, setFilterText] = React.useState('');
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [show, setShow] = useState(0)

  async function TooglePublishBrand(id) {
    const token = Cookies.get("UserToken");
    let response = await fetch(
      Variables.API_URL + "Brand/TooglePublishBrand/" + id,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://api.playmegastorelb.com/api/",
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetBrands();
    } else {
      toast.error(result);
    }
  }


  const columns = [
    {
      name: 'ID',
      selector: row => row.BrandId,
      sortable: true,
    },
    {
      name: 'Brand Name',
      selector: row => row.Brand1,
      sortable: true,
    },
    {
      name: 'Published',
      selector: row => row.IsPublished ? <AiOutlineCheck /> : <AiOutlineClose />,

    },
    {
      name: 'Options',
      selector: row => <div>
        {permissions.PublishBrand === true ? (<button className="btn btn-outline-secondary btn-sm" onClick={() => TooglePublishBrand(row.BrandId)}>{row.IsPublished ? <BiHide /> : <BiShowAlt />} </button>) : null}
        {permissions.ManageBrand === true ? (<NavLink to={"/Brand/" + row.BrandId} className="btn btn-outline-warning btn-sm ms-2" ><BiEdit /></NavLink>) : null}
        {permissions.DeleteBrand && (<button className="btn btn-outline-danger btn-sm ms-2" onClick={() => setShow(row.BrandId)}><BiTrash /></button>)}
        <Modal
          show={row.BrandId === show}
          onHide={() => setShow(0)}
        >
          <Modal.Header>
            <Modal.Title>Delete Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <p>
                  Are you sure you want to
                  <b> Delete</b> the brand named <b>{row.Brand1}</b>?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="container">
              <div className="row">
                <div className="col-8"></div>
                <div className="col-2">
                  <Button
                    variant="primary"
                    className="form-control"
                    onClick={() => DeleteBrand(row.BrandId)}
                  >
                    Yes
                  </Button>
                </div>
                <div className="col-2">
                  <Button
                    variant="secondary"
                    className="form-control"
                    onClick={() => setShow(0)}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>,
    }
  ];

  async function GetBrands() {
    const URL = Variables.API_URL + "Brand/GetBrands";
    const response = await fetch(URL)
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setBrands(listItems);
  }
  const DeleteBrand = async (BrandId) => {
    const token = Cookies.get("UserToken");
    let response = await fetch(
      Variables.API_URL + "Brand/DeleteBrand/" + BrandId,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://api.playmegastorelb.com/api/",
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetBrands();
    } else {
      toast.error("Please Check With Administrator");
    }
  };



  //#region Use Effect 
  useEffect(() => {
    GetBrands();
  }, [])
  //#endregion Use Effect 
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>List Brands</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li>List Brands</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {/* <div className="card-header">
                <div className="row">
                  <div className="col-8">
                    <h3>List Brands</h3>
                  </div>
                  <div className="col-4 text-right">
                    {permissions.ManageBrand === true ? (<NavLink to='/Brand/0' className="btn btn-primary">New Brand</NavLink>) : null}
                  </div>
                </div>

              </div> */}
              <div className="card-body">
                <div className="text-right mb-3">
                  {permissions.ManageBrand === true ? (<NavLink to='/Brand/0' className="btn btn-primary">New Brand</NavLink>) : null}
                </div>
                <div className="portalTable">
                  <DataTable
                    columns={columns}
                    data={listBrands}
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick />
      </div>
    </div>
  )
}
export default ListBrands;