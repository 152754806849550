import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { NavLink } from "react-router-dom"
import { Variables } from "../../Variations/Variations"
import { BiShowAlt, BiCheck, BiX, BiTrash } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import { GrLock, GrUnlock } from 'react-icons/gr';
import ReactTooltip from "react-tooltip";
import Util from "../../Classes/Util";
import { AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";

const ListCustomers = () => {
    const [customers, setCustomers] = useState();
    const permissions = Util.decrypt(localStorage.getItem("Permissions"));

    const columns = [
        {
            name: 'ID',
            selector: row => row.CustomerId
        },
        {
            name: "Customer's Full Name",
            selector: row => row.FirstName + " " + row.LastName,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Last Login Date',
            selector: row => row.LastLogin,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Locked',
            selector: (row) => (row.IsLocked == true ? <BiCheck /> : <BiX />),
        },
        {
            name: 'Options',
            selector: row => <div>

                {permissions.CustomerDetails && (<NavLink to={"/ViewCustomer/" + row.CustomerId} className="btn btn-outline-secondary btn-sm" ><BiShowAlt data-tip={"View " + row.FirstName + " " + row.LastName} /></NavLink>)}
                {permissions.LockCustomer && (<button className="btn btn-outline-danger btn-sm ms-2 redStroke"  onClick={() => ToggleLockCustomer(row.CustomerId)}> {row.IsLocked == false ? <GrLock  dat-tip="Lock" /> : <GrUnlock  data-tip="Unlock" />}</button>)}
                <ReactTooltip place="bottom" className="tooltip" effect="solid" />
            </div>,
        },

    ];

    const ToggleLockCustomer = async (CustomerId) => {
        const token = Cookies.get("UserToken");
        let response = await fetch(
            Variables.API_URL + "Customer/ToggleLockCustomer/" + CustomerId,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "https://api.playmegastorelb.com/api/",
                },
            }
        );
        let result = await response.json();
        if (response.ok) {
            toast.success(result);
            GetCustomers();
        } else {
            toast.error(result);
        }
    };
    async function GetCustomers() {
        const token = Cookies.get("UserToken");
        const URL = Variables.API_URL + "Customer/GetCustomers";
        const response = await fetch(URL,{
            headers:{
                "Authorization": `Bearer ${token}`,
            }
        })
        if (!response.ok) throw Error("Did Not Receive expected data");
        const listItems = await response.json();
        setCustomers(listItems);
    }


    //#region Use Effect 
    useEffect(() => {
        GetCustomers();
    }, [])
    //#endregion Use Effect 
    return (
        <div className="rightSide">
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-12">
                        <h2>List Customers</h2>
                        <ul className="portalPath">
                            <li>Home <AiOutlineRight /></li>
                            <li>List Customers</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="portalTable">
                                    <DataTable
                                        columns={columns}
                                        data={customers}
                                        pagination />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick />
            </div>
        </div>
    )
}
export default ListCustomers;