
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Variables } from "../../Variations/Variations";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";

const ManageCouponGroup = () => {
    let { CouponGroupID } = useParams();
    let navigate = useNavigate();
    const token = Cookies.get("UserToken");
    const [name, setName] = useState("")

    function IsValid() {
        if (name === "") {
            toast.error(" Name is Required");
            return false;
        }
        return true
    }





    function HandleSubmit(e) {
        //debugger;
        e.preventDefault();
        if (IsValid() == true) {
            if (CouponGroupID == 0) {
                const URL = Variables.API_URL + "CouponGroup/AddCouponGroup";
                fetch(URL, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        "Authorization": `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Name: name,
                    })
                }).then(response => {
                    if (response.ok) {
                        navigate('/CouponGroups');
                        toast.success("Added Successfully")
                    }
                    else {
                        toast.error(response);
                    }
                })
            }
            else {

                const URL = Variables.API_URL + "CouponGroup/UpdateCouponGroup/" + CouponGroupID;
                fetch(URL, {
                    method: 'Put',
                    headers: {
                        'Accept': 'application/json',
                        "Authorization": `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Name: name,
                    })
                }).then(response => {
                    if (response.ok) {
                        navigate('/CouponGroups');
                        toast.success("Updated Successfully")
                    }
                    else {
                        toast.error(response);
                    }
                })

            }
        }
    }
    async function GetGroup(CouponGroupID) {
        const URL = Variables.API_URL + "CouponGroup/GetCouponGroup/" + CouponGroupID;
        const response = await fetch(URL,{
            headers:{
                "Authorization": `Bearer ${token}`,
            }
        });
        if (!response.ok) throw Error("Did Not Receive expected data");
        const listItems = await response.json();
        setName(listItems.Name)
    }


    useEffect(() => {
       if(Cookies.get("UserToken") !== undefined){
        if (CouponGroupID != 0) {
            GetGroup(CouponGroupID);
        }
       } else {
        navigate("/Login");
       }
    }, [])

    return (
        <div className="rightSide">
            <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-12">
                <h2>{CouponGroupID == "0" ? "Add" : "Update"} Coupon Group</h2>
                <ul className="portalPath">
                  <li>Home <AiOutlineRight /></li>
                  <li><Link to="/CouponGroups">Coupon Group <AiOutlineRight /></Link></li>
                  <li>Manage Coupon Group</li>
                </ul>
              </div>
            </div>
                <div className="row">
                    <div className="col-12">
                        {/* <form> */}
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <label className="mb-2"> Name <span className="required">*</span></label>
                                                <input className="form-control" required onChange={(e) => setName(e.target.value)} value={name} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ManageCouponGroup;
