import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { Variables } from "../../Variations/Variations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";

const ManageCountry = () => {
  let { CountryCode } = useParams();
  const token = Cookies.get("UserToken");
  let navigate = useNavigate();
  const [country, setCountry] = useState({
    CountryName: "",
    ShippingRate: 0,
    PhoneCode: "",
    CountryCode: "",
  });

  function IsValid() {
    if (country.ShippingRate == 0 || isNaN(country.ShippingRate)) {
      toast.error("Shipping Rate is Required");
      return false;
    }

    return true;
  }

  function HandleSubmit(e) {
    //debugger;
    e.preventDefault();
    if (IsValid() == true) {
      const URL =
        Variables.API_URL + "Country/UpdateShippingRate/" + CountryCode;
      fetch(URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ShippingRate: country.ShippingRate,
        }),
      }).then((response) => {
        if (response.ok) {
          navigate("/Countries");
        } else {
          toast.error(response);
        }
      });
    }
  }
  async function GetCountry(coutryCode) {
    const URL =
      Variables.API_URL + "Country/GetCoutryByCountryCode/" + coutryCode;
    const response = await fetch(URL,{
      headers:{
        "Authorization": `Bearer ${token}`,
      }
    });
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setCountry({
      CountryName: listItems.CountryName,
      ShippingRate: listItems.ShippingRate,
      PhoneCode: listItems.PhoneCode,
      CountryCode: listItems.CountryCode,
    });
  }

  useEffect(() => {
    if(Cookies.get("UserToken") !== undefined){
      GetCountry(CountryCode);
    }else {navigate("/Login");
  }
  }, []);

  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>{country.CountryName == "" ? "Add" : "Update"} Country</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Countries">Countries <AiOutlineRight /></Link></li>
              <li>Manage Country</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {/* <form> */}
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-lg-8">
                    <h3>{country.CountryName}</h3>
                  </div>
                  <div className="col-lg-4 text-right">
                    <NavLink to="/Countries" className="btn btn-secondary">
                      Cancel
                    </NavLink>
                    <button
                      type="submit"
                      className="btn btn-primary ml-5"
                      onClick={HandleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <label className="mb-2">
                          Country Name <span className="required">*</span>
                        </label>
                        <input
                          className="form-control"
                          value={country.CountryName}
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg-12">
                        <label className="mb-2" >
                          Country Code<span className="required">*</span>
                        </label>
                        <input
                          className="form-control"
                          value={country.CountryCode}
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg-12 mt-3">
                        <label className="mb-2" >Phone Code</label>
                        <input
                          className="form-control"
                          value={country.PhoneCode}
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg-12 mt-3">
                        <label className="mb-2">Shipping Rate </label>
                        <input
                          type="number"
                          className="form-control"
                          value={country.ShippingRate}
                          onChange={(e) =>
                            setCountry((prevState) => ({
                              ...prevState,
                              ShippingRate: parseFloat(e.target.value),
                            }))
                          }
                          min="0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageCountry;
