import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { NavLink, useNavigate } from "react-router-dom"
import { Variables } from "../../Variations/Variations";
import {BiShowAlt,BiCheck ,BiX,BiEdit} from 'react-icons/bi'
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import Cookies from "js-cookie";
const SendersList = () => {
    const token = Cookies.get("UserToken");
    const navigate = useNavigate();
    const [senders, setSenders] = useState();
 
    const columns = [
        {
            name: 'ID',
            selector: row => row.SenderDetailId
        },
        {
            name: 'Sender Name ',
            selector: row => row.SenderName
        },
        {
            name: 'Sender Country Code',
            selector: row => row.SenderCountryCode,
            sortable: true,
            reorder: true,
        },
        {
            name:'Sender Address',
            selector: (row) => row.SenderAdd1,
        },
       
        {
            name: 'Options',
            selector: row => <div>
               <NavLink to={"/Senders/"+row.SenderDetailId} className="btn btn-icon ">  <BiEdit /> </NavLink>
             
            </div>,
        },
      
    ];

   
  
    async function GetShipmentSenders() {
        const URL = Variables.API_URL + "ShipmentSender/GetShipmentSenders";
        const response = await fetch(URL,{
            headers:{
                "Authorization": `Bearer ${token}`,
            }
        })
        if (!response.ok) throw Error("Did Not Receive expected data");
        const listItems = await response.json();
        setSenders(listItems);
    }
       //#region Use Effect 
       useEffect(() => {
        if(Cookies.get("UserToken") !== undefined){
            GetShipmentSenders();
        } else {
            navigate("/Login");
        }
    }, [])
    //#endregion Use Effect 
    return (
        <div className="rightSide">
        <div className="container-fluid mt-3">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h3>List of Senders</h3>
                                    </div>
                                    <div className="col-lg-4 text-right">
                                     <NavLink to='/Senders/0' className='btn btn-primary'>Add Sender</NavLink>
                                    </div>
                                </div>
                        </div>
                        <div className="card-body">
                            <DataTable
                                columns={columns}
                                data={senders}
                                pagination />
                        </div>
                    </div>
                </div>
            </div>
         
            
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick />
        </div>
        </div>
    )
}
export default SendersList;