export const Variables ={
    API_URL:"https://api.playmegastorelb.com/api/",
    //API_URL:"http://localhost:44342/api/",
    //PHOTO_URL:"https://api.playmegastorelb.com/Photos/",
    PHOTO_URL:"https://api.playmegastorelb.com/Uploads/",
    //API_PUBLISHED:"https://localhost:44342/Uploads/",
    API_PUBLISHED:"https://api.playmegastorelb.com/Uploads/",

    Server_key:"AAAAyNN1NfI:APA91bG_YdTJpD0VjuDZLGtOzLvjT2pc0hZsFCDMsNiHICCwSU-Bi_9_zMCHUch7IFpP_3-Ibq2SHOq9k9QcUi7Jns_eyqThS2RKecmAE7yKxk_e6YTVjHduH4Y2DSK59ycFNGSpviA1"
}

 //API_URL:"https://localhost:44342/api/",
//  API_URL:"https://playapi.vision-more.com/api/",
 //PHOTO_URL:"https://localhost:44339/Photos/",
//  API_PUBLISHED:"https://playapi.vision-more.com/Uploads/",