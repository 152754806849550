import React, { useState, useEffect } from "react";
import {
  AiOutlineInbox,
  AiOutlineFileDone,
  AiOutlineStar,
  AiFillStar,
  AiOutlineDelete,
  AiOutlineUndo,
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineRight,
} from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { BsEnvelopeOpen, BsEnvelope } from "react-icons/bs";
import { MdManageSearch } from "react-icons/md";
import DataTable from "react-data-table-component";
import { Variables } from "../../Variations/Variations";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import Util from "../../Classes/Util";
import {BsFillReplyFill} from 'react-icons/bs'
import Cookies from "js-cookie";
const ContactUs = (props) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState(0);
  const [contactUs, setContactUs] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [isOpen, setIsOpen] = useState(0);
  const[subject,setSubject]=useState("");
  const[message,setMessage]=useState("");
  const UserId = Util.decrypt(localStorage.getItem("UserId"));
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
const[replyModal,setReplyModal]=useState(false);
  const [notification, setNotification] = useState({
    CreatedDate: "",
    IsDeleted: false,
    IsStarred: false,
    IsResolved: false,
    CustomerName: "",
    Message: "",
    RepliedMessages: []
  });
  const token = Cookies.get("UserToken");
  const navigate= useNavigate();
  const contactUsColumns = [
    {
      name: "Customer Name",
      selector: (row) => (
        <>
          {row.IsStarred ? (
            <>
              {permissions.StarNotification && (
                <button className="btn-icon">
                  <AiFillStar
                    className="color-pink"
                    data-tip="Unstar"
                    onClick={() => ChangeStarredStatus(row.NotificationId)}
                  />
                  <ReactTooltip
                    place="bottom"
                    className="tooltip"
                    effect="solid"
                  />
                </button>
              )}
           {row.CustomerName}
            </>
          ) : (
            <>
              {permissions.StarNotification && (
                <button className=" btn-icon">
                  <AiOutlineStar
                    className="color-pink"
                    data-tip="Star"
                    onClick={() => ChangeStarredStatus(row.NotificationId)}
                  />
                  <ReactTooltip
                    place="bottom"
                    className="tooltip"
                    effect="solid"
                  />
                </button>
              )}
               {row.CustomerName}
            </>
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: "Body",
      selector: (row) =>
      row.Message.length > 25 ? row.Message.substring(0, 25) + "..." : row.Message,
      sortable: true,
    },
    {
      name: "From",
      selector: (row) =>
        Util.getSeconds(row.CreatedDate) < 60
          ? "Just now"
          : Util.getMinutes(row.CreatedDate) < 60
            ? parseInt(Util.getMinutes(row.CreatedDate)) === 1
              ? Util.getMinutes(row.CreatedDate) + " minute ago"
              : Util.getMinutes(row.CreatedDate) + " minutes ago"
            : Util.getHours(row.CreatedDate) < 12
              ? parseInt(Util.getHours(row.CreatedDate)) === 1
                ? Util.getHours(row.CreatedDate) + " hour ago"
                : Util.getHours(row.CreatedDate) + " hours ago"
              : row.CreatedDate.toString().substring(3, 10),
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <button className="btn btn-outline-info btn-sm" onClick={()=>setReplyModal(row.NotificationId)}>
            <BsFillReplyFill data-tip="Reply"/>
            <ReactTooltip place="bottom" className="tooltip" effect="solid" />
          </button>
          <Modal
            show={replyModal==row.NotificationId}
            onHide={() => setReplyModal(0)}
            dialogClassName="w-max"
          >
            <Modal.Header>
              <Modal.Title>Reply to : {row.CustomerName }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="container">
          <div className="row">
            <div className="col-12">
              <label> Subject</label>
              <input type="text" value={subject} onChange={(e)=>setSubject(e.target.value)} className="form-control" placeholder="Subject"/>
            </div>
            <div className="col-12">
              <label> Message </label>
            <CKEditor
                                                    editor={ClassicEditor}
                                                    className="form-control"
                                                    data={message}
                                                    value={message}
                                                    onChange={(event, editor) => {
                                                      const data =
                                                        editor.getData();
                                                      setMessage(data);
                                                    }}
                                                  />
            </div>
          </div>
        </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="container">
              <div className="row">
                <div className="col-12 text-right">

             
      
                    <Button
                      variant="primary"
                      className="ml-5"
                      onClick={() => Reply(row.ContactUsId)}
                    >
                      Reply
                    </Button>
                    </div>
              </div>
              </div>
            </Modal.Footer>
          </Modal>



          {permissions.NotificationMarkAs && (<button
            className="btn btn-outline-secondary btn-sm ms-2"
            onClick={() => {
              MarkAsRead(row.NotificationId);
              setIsOpen(row.NotificationId);
              GetContactMessage(row.NotificationId)
            }}
          >
            <FiEye data-tip="Read Notification" />
            <ReactTooltip place="bottom" className="tooltip" effect="solid" />
          </button>)}
          <Modal
            show={isOpen !== 0}
            onHide={() => setIsOpen(0)}
            dialogClassName="w-max"
          >
            <Modal.Header>
              <Modal.Title>Notification Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                {isOpen > 0 && (
                  <> 
                  <div className="row">
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row">
                        <div className="col-2 fw-bold">Customer Name : </div>
                        <div className="col-10">
                          {notification.CustomerName}
                        </div>
                      </div>

                      <div className="d-flex flex-row">
                        <div className="col-2 fw-bold ">Body: </div>
                        <div className="col-10">{notification.Message}</div>
                      </div>

                      <div className="d-flex flex-row">
                        <div className="col-2 fw-bold">Created Date: </div>
                        <div className="col-10">
                          {notification.CreatedDate.toLocaleString()}
                        </div>
                      </div>


                      <div className="d-flex flex-row">
                        <div className="col-2 fw-bold">Starred: </div>
                        <div className="col-10">
                          {notification.IsStarred ? (
                            <AiOutlineCheck />
                          ) : (
                            <AiOutlineClose />
                          )}
                        </div>
                      </div>

                      <div className="d-flex flex-row">
                        <div className="col-2 fw-bold">Deleted: </div>
                        <div className="col-10">
                          {notification.IsDeleted ? (
                            <AiOutlineCheck />
                          ) : (
                            <AiOutlineClose />
                          )}
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="col-2 fw-bold">Rsolved: </div>
                        <div className="col-10">
                          {notification.IsResolved ? (
                            <AiOutlineCheck />
                          ) : (
                            <AiOutlineClose />
                          )}
                        </div>
                      </div>



                    </div>
                  </div>
                  <br />
                  <br />
                  {notification.RepliedMessages.length!=0 && (
                  <div className="row">
                  <div className="col-12">
                    <label className="fw-bold">  Sent Messages</label> 
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    {notification.RepliedMessages.map((m,i)=>{
                      return(
                        <> 
                        <div className="col-6">
                          <label className="fw-bold"> Subject : </label> {m.Subject}
                          </div>
                          <div className="col-6">
                          <label className="fw-bold">Message : </label>  <div
                                    key={i}
                                    dangerouslySetInnerHTML={{
                                      __html: m.Message,
                                    }}
                                  />
                          </div>
                        </>
                      )
                    })}
                  </div>
                    </div>
                    )}
                    </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="container">
                <div className="row">
                  <div className="col-12 text-right">

                    <Button
                      variant="secondary"
                      className="ml-5"
                      onClick={() => setIsOpen(0)}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
          {permissions.NotificationMarkAs && (
            <>
              {row.IsOpened ? (

                <button className="btn btn-outline-warning btn-sm ms-2">
                  <BsEnvelope
                    data-tip="Mark as unread"
                    onClick={() => MarkAsUnread(row.NotificationId)}
                  />
                  <ReactTooltip place="bottom" className="tooltip" effect="solid" />
                </button>
              ) : (
                <button className="btn btn-outline-warning btn-sm ms-2">
                  <BsEnvelopeOpen
                    data-tip="Mark as read"
                    onClick={() => MarkAsRead(row.NotificationId)}
                  />
                  <ReactTooltip place="bottom" className="tooltip" effect="solid" />
                </button>
              )}
            </>
          )}


          {page !== 6 ? (
            <>
              {permissions.DeleteNotification && (
                <button className="btn btn-outline-danger btn-sm ms-2">
                  <AiOutlineDelete
                    data-tip="Delete"
                    onClick={() => ChangeDeleteStatus(row.NotificationId)}
                  />
                  <ReactTooltip place="bottom" className="tooltip" effect="solid" />
                </button>)}
            </>
          ) : (
            <>
              {permissions.RestoreNotification && (
                <button className="btn btn-outline-danger btn-sm ms-2">
                  <AiOutlineUndo
                    data-tip="Restore"
                    onClick={() => ChangeDeleteStatus(row.NotificationId)}
                  />
                  <ReactTooltip place="bottom" className="tooltip" effect="solid" />
                </button>
              )}
            </>
          )}

        </div>
      ),
      sortable: true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "View Records",
    rangeSeparatorText: "out",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.IsOpened,
      style: (row) => ({ backgroundColor: "rgba(242,245,245,0.8)" }),
    },
  ];

  const ChangeStarredStatus = async (NotificationId) => {
    let response = await fetch(Variables.API_URL +

      "Notification/ChangeStarStatus/" +
      NotificationId + "/" + UserId,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    if (response.ok === false) {
      response = await response.json();
      toast.error(response);
    } else {
      let newArray = [...contactUs];
      const Index = newArray.findIndex((notification) => {
        return notification.NotificationId === NotificationId;
      });
      newArray[Index].IsStarred = !newArray[Index].IsStarred;
      setContactUs(newArray);
      props.setContactUs(newArray);
    }
  };
const Reply =async(ContactUsId)=>{
  if(message==""){
    toast.error("Please enter your message");
  }else{
  let response = await fetch(
    Variables.API_URL + "ContactUs/Reply",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Variables.API_URL,
      },
      body: JSON.stringify({
        ContactUsId:ContactUsId,
        Subject:subject,
        Message:message
      }),
    }
  );

  let result = await response.json();
  if(response.status==200){
    toast.success(result);
    setReplyModal(0);
    setSubject("");
    setMessage("");
  }else{
    toast.error("Failed to send");
  }
  }

}
  const MarkAsRead = async (NotificationId) => {
    debugger
    let response = await fetch(Variables.API_URL +

      "Notification/MarkAsRead/" +
      NotificationId + "/" + UserId,
      {
        method: "POST",
        headers: {
          Accept: "application/json",

          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    if (response.ok === false) {
      response = await response.json();
      toast.error(response);
    } else {
      let newArray = [...contactUs];
      const Index = newArray.findIndex((notification) => {
        return notification.NotificationId === NotificationId;
      });
      if(page!=2){
          newArray[Index].IsOpened = true;
      }
      newArray[Index].ClickCount = newArray[Index].ClickCount + 1;
      setContactUs(newArray);
      props.setContactUs(newArray);
    }
  };

  const MarkAsUnread = async (NotificationId) => {
    let response = await fetch(Variables.API_URL +

      "Notification/MarkAsUnread/" +
      NotificationId + "/" + UserId,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    if (response.ok === false) {
      response = await response.json();
      toast.error(response);
    } else {
      let newArray = [...contactUs];
      const Index = newArray.findIndex((notification) => {
        return notification.NotificationId === NotificationId;
      });
      let value = newArray[Index].IsOpened;
      newArray[Index].IsOpened = false;
      setContactUs(newArray);
      props.setContactUs(newArray);
    }
  };

  const ChangeDeleteStatus = async (NotificationId) => {
    let response = await fetch(Variables.API_URL +

      "Notification/ChangeDeleteStatus/" +
      NotificationId + "/" + UserId,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    if (response.ok === false) {
      response = await response.json();
      toast.error(response);
    } else {
      let newArray = [...contactUs];
      const Index = newArray.findIndex((notification) => {
        return notification.NotificationId === NotificationId;
      });
      let value = newArray[Index].IsDeleted;
      newArray[Index].IsDeleted = !value;
      newArray[Index].IsStarred = false;
      setContactUs(newArray);
      props.setContactUs(newArray);
    }
  };
  const GetContactMessage = async (NotificationId) => {
    let response = await fetch(Variables.API_URL +

      "Notification/GetContactUsMessage/" +
      NotificationId ,
      {
        method: "Get",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok === false) {
     
      toast.error(result);
    } else {
     setNotification(result)
    }
  };





  useEffect(() => {

if(Cookies.get("UserToken") !== undefined){
  setContactUs(props.contactUs);
  let types = [];
  if (props.contactUs.length != 0) {
    props.contactUs.forEach((notification) => {
      if (
        !types.some(
          (type) =>
            type.NotificationTypeId ===
            notification.NotificationTypeId
        )
      ) {
        types.push({
          NotificationTypeId: notification.NotificationTypeId,
          Type: notification.Type,
        });
      }
    });
    setNotificationTypes(types);
  }
}else {
  navigate("/Login");
}


  }, [props.contactUs]);

  return (
    <div className="rightSide">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2>ContactUs</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li>ContactUs</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {/* <div className="card-header">
                <div className="row">
                  <div className="col-6">
                    <h5 className="card-title">ContactUs</h5>
                  </div>
                </div>
              </div> */}

              <div className="card-body">
                <div className="row">
                  <div className="col-2">
                    <div className="flex-column notification">
                      <div
                        className={page === 1 ? "nav-item active" : "nav-item"}
                        onClick={() => setPage(1)}
                      >
                        <AiOutlineInbox className="mr-5" /> All
                        <div className="numbernotification">
                          {
                            contactUs.filter(
                              (notification) => !notification.IsDeleted
                            ).length
                          }
                        </div>
                      </div>
                      <div
                        className={page === 2 ? "nav-item active" : "nav-item"}
                        onClick={() => setPage(2)}
                      >
                        <FiEyeOff className="mr-5" /> Not Opened
                      </div>
                      <div
                        className={page === 3 ? "nav-item active" : "nav-item"}
                        onClick={() => setPage(3)}
                      >
                        <FiEye className="mr-5" /> Read
                      </div>

                      <div
                        className={page === 5 ? "nav-item active" : "nav-item"}
                        onClick={() => setPage(5)}
                      >
                        <AiOutlineStar className="mr-5" />
                        Starred
                      </div>
                      <div
                        className={page === 6 ? "nav-item active" : "nav-item"}
                        onClick={() => setPage(6)}
                      >
                        <AiOutlineDelete className="mr-5" />
                        Deleted
                      </div>
                    </div>
                  </div>
                  <div className="right-tab col-10">
                    <div className="row mb-3">
                      <div className="col-8"></div>
                      <div className="col-4 text-end tblSearchBar position-relative">
                        <span>
                          <MdManageSearch />
                        </span>
                        <input
                          type="text"
                          placeholder="Search any field in table"
                          className="form-control form-control-sm"
                          onChange={(e) => setSearch(e)}
                        ></input>
                      </div>

                    </div>
                    {page === 1 && (
                      <div className="portalTable">
                        <DataTable
                          columns={contactUsColumns}
                          data={
                            searchType === 0
                              ? contactUs.filter(
                                (notification) =>
                                  !notification.IsDeleted &&
                                  (notification.Title.toLowerCase().includes(
                                    search.toLowerCase()
                                  ) ||
                                    notification.Body.toLowerCase().includes(
                                      search.toLowerCase()
                                    ))
                              )
                              : contactUs.filter(
                                (notification) =>
                                  !notification.IsDeleted &&
                                  notification.NotificationTypeId ===
                                  searchType &&
                                  (notification.Title.toLowerCase().includes(
                                    search.toLowerCase()
                                  ) ||
                                    notification.Body.toLowerCase().includes(
                                      search.toLowerCase()
                                    ))
                              )
                          }
                          pagination
                          paginationComponentOptions={paginationComponentOptions}
                          conditionalRowStyles={conditionalRowStyles}
                        />
                      </div>
                    )}
                    {page === 2 && (
                      <div className="portalTable">
                        <DataTable
                          columns={contactUsColumns}
                          data={
                            searchType === 0
                              ? contactUs.filter(
                                (notification) =>
                                  !notification.IsOpened &&
                                  !notification.IsDeleted &&
                                  (notification.Title.toLowerCase().includes(
                                    search.toLowerCase()
                                  ) ||
                                    notification.Body.toLowerCase().includes(
                                      search.toLowerCase()
                                    ))
                              )
                              : contactUs.filter(
                                (notification) =>
                                  !notification.IsOpened &&
                                  !notification.IsDeleted &&
                                  notification.NotificationTypeId ===
                                  searchType &&
                                  (notification.Title.toLowerCase().includes(
                                    search.toLowerCase()
                                  ) ||
                                    notification.Body.toLowerCase().includes(
                                      search.toLowerCase()
                                    ))
                              )
                          }
                          pagination
                          paginationComponentOptions={paginationComponentOptions}
                          conditionalRowStyles={conditionalRowStyles}
                        />
                      </div>
                    )}
                    {page === 3 && (
                      <div className="portalTable">
                        <DataTable
                          columns={contactUsColumns}
                          data={
                            searchType === 0
                              ? contactUs.filter(
                                (notification) =>
                                  notification.IsOpened &&
                                  !notification.IsDeleted &&
                                  (notification.Title.toLowerCase().includes(
                                    search.toLowerCase()
                                  ) ||
                                    notification.Body.toLowerCase().includes(
                                      search.toLowerCase()
                                    ))
                              )
                              : contactUs.filter(
                                (notification) =>
                                  notification.IsOpened &&
                                  !notification.IsDeleted &&
                                  notification.NotificationTypeId ===
                                  searchType &&
                                  (notification.Title.toLowerCase().includes(
                                    search.toLowerCase()
                                  ) ||
                                    notification.Body.toLowerCase().includes(
                                      search.toLowerCase()
                                    ))
                              )
                          }
                          pagination
                          paginationComponentOptions={paginationComponentOptions}
                          conditionalRowStyles={conditionalRowStyles}
                        />
                      </div>
                    )}
                    {page === 4 && (
                      <div className="portalTable">
                        <DataTable
                          columns={contactUsColumns}
                          data={
                            searchType === 0
                              ? contactUs.filter(
                                (notification) =>
                                  notification.IsResolved &&
                                  !notification.IsDeleted &&
                                  (notification.Title.toLowerCase().includes(
                                    search.toLowerCase()
                                  ) ||
                                    notification.Body.toLowerCase().includes(
                                      search.toLowerCase()
                                    ))
                              )
                              : contactUs.filter(
                                (notification) =>
                                  notification.IsResolved &&
                                  !notification.IsDeleted &&
                                  notification.NotificationTypeId ===
                                  searchType &&
                                  (notification.Title.toLowerCase().includes(
                                    search.toLowerCase()
                                  ) ||
                                    notification.Body.toLowerCase().includes(
                                      search.toLowerCase()
                                    ))
                              )
                          }
                          pagination
                          paginationComponentOptions={paginationComponentOptions}
                          conditionalRowStyles={conditionalRowStyles}
                        />
                      </div>
                    )}
                    {page === 5 && (
                      <div className="portalTable">
                        <DataTable
                          columns={contactUsColumns}
                          data={
                            searchType === 0
                              ? contactUs.filter(
                                (notification) =>
                                  notification.IsStarred &&
                                  !notification.IsDeleted &&
                                  (notification.Title.toLowerCase().includes(
                                    search.toLowerCase()
                                  ) ||
                                    notification.Body.toLowerCase().includes(
                                      search.toLowerCase()
                                    ))
                              )
                              : contactUs.filter(
                                (notification) =>
                                  notification.IsStarred &&
                                  !notification.IsDeleted &&
                                  notification.NotificationTypeId ===
                                  searchType &&
                                  (notification.Title.toLowerCase().includes(
                                    search.toLowerCase()
                                  ) ||
                                    notification.Body.toLowerCase().includes(
                                      search.toLowerCase()
                                    ))
                              )
                          }
                          pagination
                          paginationComponentOptions={paginationComponentOptions}
                          conditionalRowStyles={conditionalRowStyles}
                        />
                      </div>
                    )}
                    {page === 6 && (
                      <div className="portalTable">
                        <DataTable
                          columns={contactUsColumns}
                          data={
                            searchType === 0
                              ? contactUs.filter(
                                (notification) =>
                                  notification.IsDeleted &&
                                  (notification.Title.toLowerCase().includes(
                                    search.toLowerCase()
                                  ) ||
                                    notification.Body.toLowerCase().includes(
                                      search.toLowerCase()
                                    ))
                              )
                              : contactUs.filter(
                                (notification) =>
                                  notification.IsDeleted &&
                                  notification.NotificationTypeId ===
                                  searchType &&
                                  (notification.Title.toLowerCase().includes(
                                    search.toLowerCase()
                                  ) ||
                                    notification.Body.toLowerCase().includes(
                                      search.toLowerCase()
                                    ))
                              )
                          }
                          pagination
                          paginationComponentOptions={paginationComponentOptions}
                          conditionalRowStyles={conditionalRowStyles}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;