import React, { useState, useEffect } from 'react'
import { useParams, NavLink, useNavigate, Link } from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown'
import { Variables } from '../../Variations/Variations'
import { toast } from 'react-toastify';
import DataTable from "react-data-table-component";
import { Button, Modal } from "react-bootstrap";
import { AiOutlineRight } from 'react-icons/ai';
import Cookies from 'js-cookie';
const ManageMeasurement = () => {
  const [value, setValue] = useState("")
  let { MeasurementID } = useParams()
  const navigate = useNavigate();
  const token =Cookies.get("UserToken");

  async function GetMeasurement() {
    const URL = Variables.API_URL + "Measurement/GetMeasurement/" + MeasurementID;
    const response = await fetch(URL,{
      headers:{
        "Authorization": `Bearer ${token}`,
      }
    });
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setValue(listItems.MeasurementValue)
  }



  function IsValid() {
    if (value !== "") {

      return true;
    }

    return false;
  }
  function handleSubmit(e) {
    //debugger;
    if (IsValid()) {
      e.preventDefault();
      if (MeasurementID == 0) {
        const AddMeasurement = async () => {
          let response = await fetch(Variables.API_URL + "Measurement/AddMeasurement", {
            method: "Post",
            headers: {
              Accept: "application/json",
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              MeasurementValue: value
            }),
          });
          let result = await response.json();
          if (response.status === 200) {
            toast.success(result);
            navigate("/Measurements")

          }
          else {
            toast.error(result);
          }
        }
        setTimeout(() => {
          (async () => await AddMeasurement())();
        }, 100);
      }
      else {
        const UpdateMeasurement = async () => {
          let response = await fetch(Variables.API_URL + "Measurement/UpdateMeasurement/" + MeasurementID, {
            method: "Put",
            headers: {
              Accept: "application/json",
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              MeasurementValue: value
            }),
          });
          let result = await response.json();
          if (response.status === 200) {
            toast.success(result);
            navigate("/Measurements")

          }
          else {
            toast.error(result);
          }
        }
        setTimeout(() => {
          (async () => await UpdateMeasurement())();
        }, 100);
      }

    }
    else {

      toast.error("Please fill all required fields!")
    }

  }


  useEffect(() => {
 if(Cookies.get("UserToken") !== undefined){
  if (MeasurementID != 0) {
    GetMeasurement()
  }
 }else {
  navigate("/Login");
 }

  }, [])
  return (
    <div className='rightSide'>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>{MeasurementID == "0" ? "Add" : "Update"} Measurement</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Measurements">Measurements <AiOutlineRight /></Link></li>
              <li>Manage Measurement</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-lg-8">
                    <h3>{value}</h3>
                  </div>
                  <div className="col-lg-4 text-right">
                    <NavLink to='/Measurements' className='btn btn-secondary'>Cancel</NavLink>
                    <button type="submit" className="btn btn-primary ms-2" onClick={handleSubmit}>Save</button>
                  </div>

                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4">

                    <div className="col-lg-12">

                      <label>Measurement Value <span className="required">*</span></label>
                      <input className="form-control required" required id="value " onChange={(e) => setValue(e.target.value)} value={value !== '' ? value : ""} placeholder="Measurement Value..." />
                    </div>




                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageMeasurement