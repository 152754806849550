import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Variables } from "../../Variations/Variations";
import { ToastContainer, toast } from "react-toastify";
import Util from "../../Classes/Util";
import { AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";

const PushNotification = (props) => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const token = Cookies.get("UserToken");

  const [filteredList, setFilteredList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const columns = [
    {
      name: "ID",
      selector: (row) => <label> {row.PushNotificationID}</label>,
    },
    {
      name: <label> Title </label>,
      selector: (row) => row.Title,
    },
    {
      name: <label> Message </label>,
      selector: (row) => row.Message,
    },
    {
      name: <label> Sent Date </label>,
      selector: (row) => formatDate(row.DateCreated),
    },
  ];

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const time = hours + ":" + minutes + " " + ampm;
    return `${day}/${month}/${year} - ${time}`;
  };

  React.useEffect(() => {
    GetNotifications();
  }, []);

  const GetNotifications = async () => {
    const URL = Variables.API_URL + "PushNotification/GetAll";
    const response = await fetch(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setFilteredList(listItems);
  };

  const handleCancelNotification = () => {
    setTitle("");
    setMessage("");
  };

  const handleSendNotification = (e) => {
    e.preventDefault();

    if (!isLoading) {
      if (title == null || title == "") {
        toast.error("Please enter notification title!");
      } else if (message == null || message == "") {
        toast.error("Please enter notification message!");
      } else {
        setIsLoading(true);

        const URL = Variables.API_URL + "PushNotification/Add";
        fetch(URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Title: title,
            Message: message,
          }),
        })
          .then((response) => {
            setTitle("");
            setMessage("");
            props.setPushNotification(props.pushNotification + 1);
            toast.success("Notification sent successfully.");
            GetNotifications();
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again!");
          });
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    }
  };

  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>List Push Notifications</h2>
            <ul className="portalPath">
              <li>
                Home <AiOutlineRight />
              </li>
              <li>List Push Notifications</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {permissions.ManageAttribute && (
                  <div className="row">
                    <div className="col-4">
                      <form>
                        <label>Title</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Notification Title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        ></input>
                        <br />
                        <label>Message</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Notification Message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></input>
                        <br />
                      </form>
                    </div>
                    <div className="col-8 text-end">
                      <button
                        className="btn btn-secondary"
                        onClick={handleCancelNotification}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary ms-2"
                        onClick={handleSendNotification}
                      >
                        {isLoading ? "Sending..." : "Send"}
                      </button>
                    </div>
                  </div>
                )}
                <div className="portalTable">
                  <DataTable
                    columns={columns}
                    data={filteredList}
                    pagination
                    className="table table-striped"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
        />
      </div>
    </div>
  );
};

export default PushNotification;
