import React, { useState, useEffect } from "react";
import { NavLink, Link, Navigate } from "react-router-dom"
import DataTable from 'react-data-table-component';
import { Variables } from "../../Variations/Variations"
import { BiTrash, BiEdit, BiShowAlt, BiHide } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import Util from "../../Classes/Util";
import { AiOutlineClose, AiOutlineCheck, AiOutlineRight } from 'react-icons/ai';
import { Button, Modal } from "react-bootstrap";
import { MdManageSearch } from "react-icons/md";
import Cookies from "js-cookie";

const Countries = () => {
    const [listcountries, setcountries] = useState([]);
    const [filtered, setFiltered] = useState([])
    const permissions = Util.decrypt(localStorage.getItem("Permissions"));
    const [search, setSearch] = useState("");
    const token = Cookies.get("UserToken");
    async function TooglePublishCountry(id) {
        let response = await fetch(
            Variables.API_URL + "Country/TogglePublishCountry/" + id,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Authorization": `Bearer ${token}`,
                    "Country-Type": "application/json",
                    "Access-Control-Allow-Origin": "https://api.playmegastorelb.com/api/",
                },
            }
        );
        let result = await response.json();
        console.log(result)
        if (response.ok) {
            toast.success(result);
            Getcountries();
            setSearch("")
        } else {
            toast.error(result);
        }
    }


    const columns = [
        {
            name: 'ID',
            selector: row => row.CountryId,
            sortable: true,
        },
        {
            name: 'Country Name',
            selector: row => row.CountryName,
            sortable: true,
        },
        {
            name: 'Country Code',
            selector: row => row.CountryCode,
            sortable: true,
        },
        {
            name: 'Shipping Rate',
            selector: row => row.ShippingRate,
            sortable: true,
        },

        {
            name: 'Published',
            selector: row => row.IsPublished ? <AiOutlineCheck /> : <AiOutlineClose />,
            sortable: true,
        },
        {
            name: 'Options',
            selector: row => <div>
                {permissions.PublishCountry && (<button className="btn btn-outline-secondary btn-sm" onClick={() => TooglePublishCountry(row.CountryId)}>{row.IsPublished ? <BiHide /> : <BiShowAlt />} </button>)}
                {permissions.ManageCountry && (<NavLink to={"/Countries/" + row.CountryCode} className="btn btn-outline-warning btn-sm ms-2" ><BiEdit /></NavLink>)}

            </div>,
        }
    ];

    async function Getcountries() {
        const URL = Variables.API_URL + "Country/GetAllCountries";
        const response = await fetch(URL,{
            headers:{
                "Authorization": `Bearer ${token}`,
            }
        })
        if (!response.ok) throw Error("Did Not Receive expected data");
        const listItems = await response.json();
        setcountries(listItems);
        setFiltered(listItems)
    }

    function handleSearch(e) {
        setSearch(e.target.value)
        let value = e.target.value.toLowerCase();
        let result = [];
        result = listcountries.filter((data) => {
            return data.CountryName.toLowerCase().search(value) != -1;
        });
        setFiltered(result);
    }


    //#region Use Effect 
    useEffect(() => {
        if(Cookies.get("UserToken") !== undefined){
        Getcountries();

    } else {
        Navigate("/Login");
    }
    }, [])
    //#endregion Use Effect 
    return (
        <div className="rightSide">
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-12">
                        <h2>List Countries</h2>
                        <ul className="portalPath">
                            <li>Home <AiOutlineRight /></li>
                            <li>List Countries</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-8">
                                        <h3>List Countries</h3>
                                    </div>

                                </div>

                            </div>
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-8"></div>
                                    <div className="col-4 text-end tblSearchBar position-relative">
                                        <span>
                                            <MdManageSearch />
                                        </span>
                                        <input
                                            type="text"
                                            placeholder="Search any field in table"
                                            className="form-control form-control-sm"
                                            onChange={(e) => handleSearch(e)}
                                        ></input>
                                    </div>
                                </div>
                                <div className="portalTable">
                                    <DataTable
                                        columns={columns}
                                        data={filtered}
                                        pagination
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick />
            </div>
        </div>
    )
}
export default Countries;