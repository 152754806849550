import React, { useEffect, useState, useCallback } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { Variables } from "../../Variations/Variations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Modal } from "react-bootstrap";
import { Button, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Dropzone from "react-dropzone";
import axios from "axios";
import Util from "../../Classes/Util";
import Pagination from "../Products/Pagination/Pagination";
import { MdManageSearch, MdOutlineAssignmentReturned, MdOutlineDelete } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
const ManageCategory = () => {
  //#region Variables
  let { CategoryID } = useParams();
  let navigate = useNavigate();
  const [productOrder, setProductOrder] = useState(0);
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [categoryName, setCategoryName] = useState("");
  const [parentID, setParentID] = useState(0);
  const [includeMenu, setIncludeMenu] = useState(false);
  const [includeFilter, setIncludeFilter] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState("");
  const [parentCategories, setParentCategories] = useState([]);
  const [picture, setPicture] = useState(0);
  const [thumb, setThumb] = useState(0);
  const [isOpenSecondImage, setIsOpenSecondImage] = useState(false);
  const [isOpenFirstImage, setIsOpenFirstImage] = useState(false);
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [groups, setGroups] = useState([]);
  const [groupID, setGroupID] = useState(0);
  const [assignedMeasurements, setAssignedMeasurements] = useState([]);
  const [filteredAssignedMeasurements, setFilteredAssignedMeasurements] =
    useState([]);
  const [unassignedMeasurements, setUnassignedMeasurements] = useState([]);
  const [filteredUnassignedMeasurements, setFilteredUnassignedMeasurements] =
    useState([]);
  const [isOpenAssignMeasurement, setIsOpenAssignMeasurement] = useState(false);
  const [assignedSizes, setAssignedSizes] = useState([]);
  const [filteredAssignedSizes, setFilteredAssignedSizes] = useState([]);
  const [unassignedSizes, setUnassignedSizes] = useState([]);
  const [filteredUnassignedSizes, setFilteredUnassignedSizes] = useState([]);
  const [isOpenAssignSize, setIsOpenAssignSize] = useState(false);
  const [order, setOrder] = useState(0)
  const [Media, setMedia] = useState([]);
  const [filteredData, setFilteredData] = useState(Media);
  let NUM_OF_RECORDS = Media.length;
  let LIMIT = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const currentData = filteredData.slice(
    (currentPage - 1) * LIMIT,
    (currentPage - 1) * LIMIT + LIMIT
  );

  const onPageChanged = useCallback(
    (event, page) => {
      event.preventDefault();
      setCurrentPage(page);
    },
    [setCurrentPage]
  );
  //#endregion Variables

  //#region Handlers

  function IsValid() {
    if (categoryName !== "") {
      return true;
    }
    return false;
  }

  const HandleSubmit = async (e) => {
    if (IsValid()) {
      e.preventDefault();
      const token = Cookies.get("UserToken");
      if (CategoryID == 0) {
        let response = await fetch(
          Variables.API_URL + "Category/AddCategory",
          {
            method: "Post",
            headers: {
              Accept: "application/json",
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              CategoryName: categoryName,
              ParentID: parentID,
              InMenu: includeMenu,
              IsFiltered: includeFilter,
              IsPublished: isPublished,
              Description: categoryDetails,
              Image: picture,
              Icon: thumb,
              DisplayProductsOrderId: productOrder,
              CategoryOrder: order
            }),
          }
        );
        let result = await response.json();
        if (response.status === 200) {
          toast.success("Added successfully");
          navigate("/Category");
          fetch(Variables.API_URL + "Category/AssignGroupToCategory", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              CategoryId: result,
              GroupId: groupID,
            }),
          });
          fetch(Variables.API_URL + "Measurement/AssignMeasurementToCategory/" + result, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(
              assignedMeasurements.map((item) => ({
                MeasurementId: item.MeasurementId,
              }))
            ),
          });
          fetch(Variables.API_URL + "AssignSizeToCategory/AssignSizeToCategory/" + result, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(
              assignedSizes.map((item) => ({
                SizeId: item.SizeId,
              }))
            ),
          });
        } else {
          toast.error(result);
        }
      }
      else {
        let response = await fetch(
          Variables.API_URL + "Category/UpdateCategory/" + CategoryID,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              CategoryName: categoryName,
              ParentID: parentID,
              InMenu: includeMenu,
              IsFiltered: includeFilter,
              IsPublished: isPublished,
              Description: categoryDetails,
              Image: picture,
              Icon: thumb,
              DisplayProductsOrderId: productOrder,
              CategoryOrder: order
            }),
          }
        );
        let result = await response.json();
        if (response.status === 200) {
          toast.success("Updated successfully");
          navigate("/Category");
          fetch(Variables.API_URL + "Category/AssignGroupToCategory", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              CategoryId: result,
              GroupId: groupID,
            }),
          });
          fetch(Variables.API_URL + "Measurement/AssignMeasurementToCategory/" + CategoryID, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(
              assignedMeasurements.map((item) => ({
                MeasurementId: item.MeasurementId,
              }))
            ),
          });
          fetch(Variables.API_URL + "AssignSizeToCategory/AssignSizeToCategory/" + CategoryID, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(
              assignedSizes.map((item) => ({
                SizeId: item.SizeId,
              }))
            ),
          });
        } else {
          toast.error(result);
        }

      }
    }
    else {
      toast.error("Please fill all required fields!");
    }

  }

  //#endregion Handlers

  async function GetCategories(categoryID) {
    const token = Cookies.get("UserToken");
    const response = await fetch(
      Variables.API_URL + "Category/GetCategory/" + categoryID,
      {
        method: "Get",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setCategoryName(listItems.CategoryName);
    setParentID(listItems.ParentId);
    setIsPublished(listItems.IsPublished);
    setIncludeFilter(listItems.IncludeFilter);
    setIncludeMenu(listItems.IncludeMenu);
    setPicture(listItems.imageid);
    setThumb(listItems.iconmid);
    setImage1(listItems.image);
    setImage2(listItems.icon);
    setOrder(listItems.CategoryOrder)

    setCategoryDetails(
      listItems.Description === null ? "" : listItems.Description
    );
    setGroupID(listItems.GroupId);
  }
  async function GetParentCategories() {
    const URL = Variables.API_URL + "Category/GetCategoriesParent";
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setParentCategories(listItems);
  }
  async function GetUnassignedMeasurements() {
    const URL =
      Variables.API_URL + "Measurement/GetUnAssignedMeasurements/" + CategoryID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setUnassignedMeasurements(listItems);
    setFilteredUnassignedMeasurements(listItems);
  }
  async function GetAssignedMeasurements() {
    const URL =
      Variables.API_URL + "Measurement/GetAssignedMeasurements/" + CategoryID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setAssignedMeasurements(listItems);
    setFilteredAssignedMeasurements(listItems);
  }

  function handlSearchAssignedMeasurement(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedMeasurements.filter((data) => {
      return data.MeasurementValue.toLowerCase().search(value) != -1;
    });
    setFilteredAssignedMeasurements(result);
  }
  function handleSearchUnassignedMeasurement(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedMeasurements.filter((data) => {
      return data.MeasurementValue.toLowerCase().search(value) != -1;
    });
    setFilteredUnassignedMeasurements(result);
  }

  function AssignMeasurement(row) {
    var assigned = [...assignedMeasurements];
    assigned.push(row);
    var unassigned = [...unassignedMeasurements];
    unassigned.forEach((element, i) => {
      if (element == row) {
        unassigned.splice(i, 1);
      }
    });
    setAssignedMeasurements(assigned);
    setFilteredAssignedMeasurements(assigned);
    setFilteredUnassignedMeasurements(unassigned);
    setUnassignedMeasurements(unassigned);
    setIsOpenAssignMeasurement(false);
  }

  function UnassignMeasurement(row) {
    var assigned = [...assignedMeasurements];
    var unassigned = [...unassignedMeasurements];
    assigned.forEach((element, i) => {
      if (element == row) {
        assigned.splice(i, 1);
      }
    });
    unassigned.push(row);
    unassigned.sort((a, b) => a.MeasurementId - b.MeasurementId)
    setAssignedMeasurements(assigned);
    setUnassignedMeasurements(unassigned);
    setFilteredAssignedMeasurements(assigned);
    setFilteredUnassignedMeasurements(unassigned);
  }

  const unassignedMeasurementsColumns = [
    {
      name: "ID",
      selector: (row) => row.MeasurementId,
      sortable: true,
    },
    {
      name: " Value ",
      selector: (row) => row.MeasurementValue,
      sortable: true,
    },

    {
      name: "Assign",
      selector: (row) => (
        <button className="btn btn-outline-secondary btn-sm" onClick={() => AssignMeasurement(row)}>
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  const assignedMeasurementsColumns = [
    {
      name: "ID",
      selector: (row) => row.MeasurementId,
      sortable: true,
    },
    {
      name: " Value ",
      selector: (row) => row.MeasurementValue,
      sortable: true,
    },

    {
      name: "UnAssign",
      selector: (row) => (
        <button className="btn btn-outline-danger btn-sm" onClick={() => UnassignMeasurement(row)}>
          <MdOutlineDelete />
        </button>
      ),
    },
  ];

  async function GetGroups() {
    const URL = Variables.API_URL + "Group/GetGroups";
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setGroups(listItems);
  }
  const handleDrop = async (files) => {
    debugger;
    var count = 0;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      if (files[i].size >= 2000000) {
        count = count + 1;
      } else {
        reader.onload = async function () {
          const formData = new FormData();
          formData.append("Image", files[i]);
          try {
            const res = await axios.post(
              Variables.API_URL + "UploadMedia/UploadImageToServer",
              formData
            );
            if (res.data !== "") {
              await fetch(Variables.API_URL + "UploadMedia/InsertMedia", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  image: res.data,
                  imageTitle: files[i].name.split(".").slice(0, -1).join("."),
                  altText: "",
                  description: "",
                  caption: "",
                }),
              }).then((Response) => {
                if (Response.ok) {
                  fetchMedia();
                } else {
                  toast.error(Response);
                }
              });
            }
          } catch (ex) {
            console.log(ex);
          }
        };
        reader.readAsDataURL(file);
      }
    }

    if (count === 1) {
      var error1 = " Image is not accepted ";
      toast.error(error1);
    } else if (count > 1) {
      var error2 = count + " Images are not accepted ";
      toast.error(error2);
    }
  };

  function fetchMedia() {
    fetch(Variables.API_URL + "UploadMedia/GetMedia", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((Response) => Response.json())
      .then((Result) => {
        setMedia(Result);
        setFilteredData(Result);
      });
  }



  async function GetUnassignedSizes() {
    const URL =
      Variables.API_URL + "AssignSizeToCategory/GetUnassignedSizes/" + CategoryID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setUnassignedSizes(listItems);
    setFilteredUnassignedSizes(listItems);

  }
  async function GetAssignedSizes() {
    const URL =
      Variables.API_URL + "AssignSizeToCategory/GetAssignedSizes/" + CategoryID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setAssignedSizes(listItems);
    setFilteredAssignedSizes(listItems);
  }

  function handleSearchAssignedSizes(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedSizes.filter((data) => {
      return data.Name.toLowerCase().search(value) != -1;
    });
    setFilteredAssignedSizes(result);
  }
  function handleSearchUnassignedSizes(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedSizes.filter((data) => {
      return data.Name.toLowerCase().search(value) != -1;
    });
    setFilteredUnassignedSizes(result);
  }

  function AssignSize(row) {
    var assigned = [...assignedSizes];
    assigned.push(row);
    var unassigned = [...unassignedSizes];
    unassigned.forEach((element, i) => {
      if (element == row) {
        unassigned.splice(i, 1);
      }
    });
    setAssignedSizes(assigned);
    setFilteredAssignedSizes(assigned);
    setFilteredUnassignedSizes(unassigned);
    setUnassignedSizes(unassigned);
    setIsOpenAssignSize(false);
  }

  function UnassignSize(row) {
    var assigned = [...assignedSizes];
    var unassigned = [...unassignedSizes];
    assigned.forEach((element, i) => {
      if (element == row) {
        assigned.splice(i, 1);
      }
    });
    unassigned.push(row);
    unassigned.sort((a, b) => a.SizeId - b.SizeId)
    setAssignedSizes(assigned);
    setUnassignedSizes(unassigned);
    setFilteredAssignedSizes(assigned);
    setFilteredUnassignedSizes(unassigned);
  }

  const unassignedSizesColumns = [
    {
      name: "ID",
      selector: (row) => row.SizeId,
      sortable: true,
    },
    {
      name: " Value ",
      selector: (row) => row.Name,
      sortable: true,
    },

    {
      name: "Assign",
      selector: (row) => (
        <button className="btn btn-outline-secondary btn-sm" onClick={() => AssignSize(row)}>
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  const assignedSizesColumns = [
    {
      name: "ID",
      selector: (row) => row.SizeId,
      sortable: true,
    },
    {
      name: " Value ",
      selector: (row) => row.Name,
      sortable: true,
    },

    {
      name: "UnAssign",
      selector: (row) => (
        <button className="btn btn-outline-danger btn-sm" onClick={() => UnassignSize(row)}>
          <MdOutlineDelete />
        </button>
      ),
    },
  ];

  //#region Use Effect
  useEffect(() => {
    if(Cookies.get("UserToken") !== undefined){
      if (CategoryID != 0) {
        GetCategories(CategoryID);
  
        GetAssignedMeasurements();
        GetAssignedSizes()
      }
      GetUnassignedMeasurements();
      GetUnassignedSizes();
      GetGroups();
      GetParentCategories();
      fetchMedia();
    } else {
      navigate("/Login")
    }
  }, []);
  //#endregion Use Effect

  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>{CategoryID == "0" ? "Add" : "Update"} Category</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Category">Category <AiOutlineRight /></Link></li>
              <li>Manage Category</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-8">
                      <h3>{categoryName}</h3>
                    </div>
                    <div className="col-4 text-right">
                      <NavLink to="/Category" className="btn btn-secondary">
                        Cancel
                      </NavLink>
                      <button
                        type="submit"
                        className="btn btn-primary ms-2"
                        onClick={HandleSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label className="mb-2">Category Name</label>
                        <input
                          className="form-control required"
                          placeholder="Category Name"
                          required
                          value={categoryName}
                          onChange={(e) => setCategoryName(e.target.value)}
                        ></input>
                      </div>

                      <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label className="mb-2">Products Order</label>
                        <select className="form-control" value={productOrder}>
                          <option key="0" value="0">
                            Order Products By
                          </option>
                        </select>
                      </div>
                      <div className="col-2">
                        <label className="mb-2"> Category Order</label>
                        <input type="number" className="form-control" value={order} onChange={(e) => setOrder(parseInt(e.target.value))} min="0" />
                      </div>
                      <div className="col-2">
                        <input
                          type="checkbox"
                          id="includeMenu"
                          onChange={(e) => {
                            setIncludeMenu(
                              e.target.checked == true ? true : false
                            );
                          }}
                          checked={includeMenu}
                        />
                        <label htmlFor="includeMenu" className="ml-3">
                          Include Menu
                        </label>
                        <br />
                        <input
                          type="checkbox"
                          id="includeFilter"
                          onChange={(e) =>
                            setIncludeFilter(
                              e.target.checked == true ? true : false
                            )
                          }
                          checked={includeFilter}
                        />
                        <label htmlFor="includeFilter" className="ml-3">
                          Include Filter
                        </label>
                        <br />
                        {permissions.PublishCategory === true ? (
                          <>
                            <input
                              type="checkbox"
                              id="isPublished"
                              onChange={(e) =>
                                setIsPublished(
                                  e.target.checked == true ? true : false
                                )
                              }
                              checked={isPublished}
                            />
                            <label htmlFor="isPublished" className="ml-3">
                              Published
                            </label>
                          </>
                        ) : null}
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label className="mb-2">Parent</label>
                          <select
                            className="form-control"
                            onChange={(e) => setParentID(e.target.value)}
                            value={parentID}
                          >
                            <option key="0" value="0">
                              Root Category
                            </option>
                            {parentCategories.map((x) => (
                              <option key={x.CategoryId} value={x.CategoryId}>
                                {x.CategoryName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <label className="mb-2">Group</label>
                          <select
                            className="form-control"
                            onChange={(e) => setGroupID(e.target.value)}
                            value={groupID}
                          >
                            <option key="0" value="0">
                              Group
                            </option>
                            {groups.map((x) => (
                              <option key={x.GroupId} value={x.GroupId}>
                                {x.GroupName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12">
                        <label className="mb-2">Description</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={categoryDetails}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setCategoryDetails(data);
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <label> Background Image</label>
                        <br />
                        <br />
                        {image1 !== "" ? (
                          <img
                            alt="media"
                            src={Variables.API_PUBLISHED + image1}
                            width="200"
                            height="250"
                          />
                        ) : null}
                        <br />
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => setIsOpenFirstImage(true)}
                        >
                          Upload Image
                        </button>
                        <Modal
                          show={isOpenFirstImage}
                          fullscreen={true}
                          onHide={() => setIsOpenFirstImage(false)}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Add Background Image</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Tabs
                              defaultActiveKey="Library"
                              id="uncontrolled-tab-example"
                              className="mb-3"
                            >
                              <Tab eventKey="Upload" title="Upload File">
                                <div className="row mt-5">
                                  <div className="row">
                                    <Dropzone
                                      onDrop={handleDrop}
                                      accept="image/jpeg,image/png,image/jpg,image/svg"
                                      style={{ height: 500 }}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div
                                          {...getRootProps({
                                            className: "dropzone",
                                          })}
                                        >
                                          <input {...getInputProps()} />
                                          <p style={{ height: 500 }}>
                                            Drag'n'drop images, or click to
                                            select files
                                          </p>
                                        </div>
                                      )}
                                    </Dropzone>
                                  </div>
                                  <div className="row">
                                    <br />
                                  </div>
                                </div>
                              </Tab>
                              <Tab eventKey="Library" title="Media Library">
                                <div className="col-12">
                                  <div className="row">
                                    {currentData.map((image) => (
                                      <div
                                        className="col-2"
                                        key={image.MediaId}
                                      >
                                        <div className="imagecontainer">
                                          <div className="form-check">
                                            <button
                                              className="btn "
                                              onClick={(e) => (
                                                setPicture(image.MediaId),
                                                setIsOpenFirstImage(false),
                                                setImage1(image.Image)
                                              )}
                                            >
                                              <label className="form-check-label">
                                                <img
                                                  alt="media"
                                                  className="form-control"
                                                  src={
                                                    Variables.API_PUBLISHED +
                                                    image.Image
                                                  }
                                                />
                                              </label>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div
                                  className="pagination-wrapper "
                                  id="pagination-tabs"
                                >
                                  <Pagination
                                    totalRecords={
                                      Media.length === filteredData.length
                                        ? NUM_OF_RECORDS
                                        : filteredData.length
                                    }
                                    pageLimit={LIMIT}
                                    pageNeighbours={2}
                                    onPageChanged={onPageChanged}
                                    currentPage={currentPage}
                                  />
                                </div>
                              </Tab>
                            </Tabs>
                          </Modal.Body>
                        </Modal>
                      </div>
                      <div className="col-6">
                        <label> Thumb nail</label>
                        <br />
                        <br />
                        {image2 !== "" ? (
                          <img
                            alt="media"
                            src={Variables.API_PUBLISHED + image2}
                            width="200"
                            height="250"
                          />
                        ) : null}
                        <br />
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => setIsOpenSecondImage(true)}
                        >
                          Upload Image
                        </button>

                        <Modal
                          show={isOpenSecondImage}
                          fullscreen={true}
                          onHide={() => setIsOpenSecondImage(false)}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Add Background Image</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Tabs
                              defaultActiveKey="Library"
                              id="uncontrolled-tab-example"
                              className="mb-3"
                            >
                              <Tab eventKey="Upload" title="Upload File">
                                <div className="row mt-5">
                                  <div className="row">
                                    <Dropzone
                                      onDrop={handleDrop}
                                      accept="image/jpeg,image/png,image/jpg,image/svg"
                                      style={{ height: 500 }}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div
                                          {...getRootProps({
                                            className: "dropzone",
                                          })}
                                        >
                                          <input {...getInputProps()} />
                                          <p style={{ height: 500 }}>
                                            Drag'n'drop images, or click to
                                            select files
                                          </p>
                                        </div>
                                      )}
                                    </Dropzone>
                                  </div>
                                  <div className="row">
                                    <br />
                                  </div>
                                </div>
                              </Tab>
                              <Tab eventKey="Library" title="Media Library">
                                <div className="col-12">
                                  <div className="row">
                                    {currentData.map((image) => (
                                      <div
                                        className="col-2"
                                        key={image.MediaId}
                                      >
                                        <div className="imagecontainer">
                                          <div className="form-check">
                                            <button
                                              className="btn "
                                              onClick={(e) => (
                                                setThumb(image.MediaId),
                                                setIsOpenSecondImage(false),
                                                setImage2(image.Image)
                                              )}
                                            >
                                              <label className="form-check-label">
                                                <img
                                                  alt="media"
                                                  className="form-control"
                                                  src={
                                                    Variables.API_PUBLISHED +
                                                    image.Image
                                                  }
                                                />
                                              </label>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div
                                  className="pagination-wrapper "
                                  id="pagination-tabs"
                                >
                                  <Pagination
                                    totalRecords={
                                      Media.length === filteredData.length
                                        ? NUM_OF_RECORDS
                                        : filteredData.length
                                    }
                                    pageLimit={LIMIT}
                                    pageNeighbours={2}
                                    onPageChanged={onPageChanged}
                                    currentPage={currentPage}
                                  />
                                </div>
                              </Tab>
                            </Tabs>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <br />
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-6">
                    <h5 className="card-title">Assigned Measurements</h5>
                  </div>
                  <div className="col-6 text-right">
                    <button
                      className="btn btn-primary"
                      onClick={() => setIsOpenAssignMeasurement(true)}
                    >
                      Assign Measurement
                    </button>
                    <Modal
                      dialogClassName="w-max"
                      show={isOpenAssignMeasurement}
                      onHide={() => setIsOpenAssignMeasurement(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Assign Request</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <div className="row mb-3">
                                <div className="col-8"></div>
                                <div className="col-4 text-end tblSearchBar position-relative">
                                  <span>
                                    <MdManageSearch />
                                  </span>
                                  <input
                                    type="text"
                                    placeholder="Search any field in table"
                                    className="form-control form-control-sm"
                                    onChange={(e) => handleSearchUnassignedMeasurement(e)}
                                  ></input>
                                </div>
                              </div>
                              <div className="portalTable">
                                <DataTable
                                  columns={unassignedMeasurementsColumns}
                                  data={filteredUnassignedMeasurements}
                                  pagination
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <div className="container">
                          <div className="row">
                            <div className="col-12 text-right">
                              <Button
                                variant="secondary"
                                onClick={() => setIsOpenAssignMeasurement(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-8"></div>
                  <div className="col-4 text-end tblSearchBar position-relative">
                    <span>
                      <MdManageSearch />
                    </span>
                    <input
                      type="text"
                      placeholder="Search any field in table"
                      className="form-control form-control-sm"
                      onChange={(e) => handlSearchAssignedMeasurement(e)}
                    ></input>
                  </div>
                </div>
                <div className="portalTable">
                  <DataTable
                    columns={assignedMeasurementsColumns}
                    data={filteredAssignedMeasurements}
                    pagination
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-6">
                    <h5 className="card-title">Assigned Sizes</h5>
                  </div>
                  <div className="col-6 text-right">
                    <button
                      className="btn btn-primary"
                      onClick={() => setIsOpenAssignSize(true)}
                    >
                      Assign Size
                    </button>
                    <Modal
                      dialogClassName="w-max"
                      show={isOpenAssignSize}
                      onHide={() => setIsOpenAssignSize(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Assign Request</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <div className="row mb-3">
                                <div className="col-8"></div>
                                <div className="col-4 text-end tblSearchBar position-relative">
                                  <span>
                                    <MdManageSearch />
                                  </span>
                                  <input
                                    type="text"
                                    placeholder="Search any field in table"
                                    className="form-control form-control-sm"
                                    onChange={(e) => handleSearchUnassignedSizes(e)}
                                  ></input>
                                </div>
                              </div>
                              <div className="portalTable">
                                <DataTable
                                  columns={unassignedSizesColumns}
                                  data={filteredUnassignedSizes}
                                  pagination
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <div className="container">
                          <div className="row">
                            <div className="col-12 text-right">
                              <Button
                                variant="secondary"
                                onClick={() => setIsOpenAssignSize(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-8"></div>
                  <div className="col-4 text-end tblSearchBar position-relative">
                    <span>
                      <MdManageSearch />
                    </span>
                    <input
                      type="text"
                      placeholder="Search any field in table"
                      className="form-control form-control-sm"
                      onChange={(e) => handleSearchAssignedSizes(e)}
                    ></input>
                  </div>
                </div>
                <div className="portalTable">
                  <DataTable
                    columns={assignedSizesColumns}
                    data={filteredAssignedSizes}
                    pagination
                  />
                </div>
                </div>
              </div>
            </div>

          </div>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
          />
        </div>
      </div >
      );
};
      export default ManageCategory;
