import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  Row,
  Col,
  Card,
  Form,
  Tab,
  Nav,
  Modal,
  Button,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Util from "../../Classes/Util";
import { Variables } from "../../Variations/Variations";
import { Link, useNavigate } from "react-router-dom";
import CustomSnipper from "../../CustomSnipper";
import { RiUserHeartFill } from 'react-icons/ri';
import { MdPayment, MdLocationPin, MdLocalShipping, MdPhoneIphone } from 'react-icons/md';
import { CgDollar } from 'react-icons/cg';
import { AiOutlineRight } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import Cookies from "js-cookie";
function ListOrders() {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [orders, setOrders] = useState([]);
  const [orderStatus, SetOrderStatus] = useState([]);
  const [ordersLoader, setOrdersLoader] = useState(false);
  const [orderDetailsModal, setOrderDetailsModal] = useState(0);
  const [orderToUpdate, setOrderToUpdate] = useState({
    OrderId: 0,
    OrderStatusId: 0,
  });
  const [filteredOrder, setFilteredOrder] = useState([])
  const [rejectModal, setRejectModal] = useState(false);
  const [reason, setReason] = useState("");
  const [search, setSearch] = useState("");
  const token = Cookies.get("UserToken");
  const navigate = useNavigate();
  // Get orders Stautses
  const GetOrdersStatus = async () => {
    let response = await fetch(
      Variables.API_URL + "Order/GetAllOrderStatuses",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      SetOrderStatus(result);
    }
  };

  // Get orders
  const GetOrders = async () => {
    let response = await fetch(
      Variables.API_URL +
      "Order/GetOrders",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setOrders(result);
      console.log("test",result);
      setFilteredOrder(result)
    }
  };

  const RejectOrder = async () => {
    var str = reason;
    var wordCount = str.length;

    if (reason != '') {
      if (wordCount > 2) {

        let response = await fetch(Variables.API_URL + "Order/RejectOrder/" + orderToUpdate.OrderId, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Reason: reason
          }),
        });
        let result = await response.json();
        if (response.status === 200) {
          toast.success(result);
          setReason("");
          setOrderDetailsModal(0);
          setRejectModal(false)
          GetOrders();
          setOrderToUpdate({
            OrderId: 0,
            statusId: 0,
          });
        } else {
          toast.error(result);
        }
      } else {
        toast.error("Please Enter A Valid Reason")
      }

    } else {
      toast.error("Please Enter Your Reason");
    }

  }


  const SaveChanges = async (e) => {
    if (orderToUpdate.OrderStatusId != 0) {
      if (orderToUpdate.OrderStatusId != 3) {
        let response = await fetch(Variables.API_URL + "Order/UpdateOrderStatus", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            OrderId: orderToUpdate.OrderId,
            OrderStatusId: orderToUpdate.OrderStatusId
          }),
        });
        let result = await response.json();
        if (response.status === 200) {
          toast.success(result);
          setOrderToUpdate({
            OrderId: 0,
            statusId: 0,
          });
          setOrderDetailsModal(0);
          GetOrders();
        } else {
          toast.error(result);
        }
      }
      else {
        setOrderDetailsModal(0);
        setRejectModal(true);
      }

    } else {
      toast.error("Please Choose A Status")
    }

  };

  // Cancel Update
  const CancelUpdate = () => {
    setOrderToUpdate({
      OrderId: 0,
      statusId: 0,
    });
    setOrderDetailsModal(0);
  };


  const columns = [
    {
      name: '#Order Number',
      selector: row => row.OrderNumber,
      sortable: true,
    },
    {
      name: "Customer",
      selector: row => row.CustomerName,
      sortable: true,

    },
    {
      name: 'Payment Method',
      selector: row => row.PaymentMethodName,
      sortable: true,

    },
    {
      name: 'Order Date',
      selector: row => row.Date,
      sortable: true,

    },
    {
      name: 'Total Without Shipping',
      selector: (row) => row.OrderTotal,
      sortable: true,
    },
    {
      name: 'Shipping Rate',
      selector: (row) => row.ShippingRate,
      sortable: true,
    },
    {
      name: 'Status ',
      selector: (row) => row.OrderStatusName,
      sortable: true,
    },
    {
      name: 'Actions',
      selector: row => <div>
        {permissions.ManageOrder && (
          <Link
            className="btn btn-outline-secondary btn-sm "
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title="Edit"
            to={"#"}
            onClick={() =>
              setOrderDetailsModal(
                row.OrderId
              )
            }
          >

            <BiEdit />

          </Link>
        )}
        <Modal

          show={
            orderDetailsModal ===
            row.OrderId
          }
          size="xl"
          onHide={() => setOrderToUpdate({ OrderId: 0, OrderStatusId: 0 })}
          aria-labelledby="contained-modal-title-vcenter"
          animation={true}
          autoFocus={true}
          keyboard={true}
          scrollable={true}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>
              {"Order# : " +
                row.OrderNumber}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <Col md="9"> </Col>
              <Col md="3">
                {row.OrderStatusId != 3 && row.OrderStatusId != 6 ? (
                  <select className="form-control" value={orderToUpdate.OrderStatusId} onChange={(e) => setOrderToUpdate({ OrderId: row.OrderId, OrderStatusId: e.target.value })}>
                    <option value={0}>--Select--</option>

                    {row.OrderStatusId == 2 ?
                      <>
                        {orderStatus.filter(child => child.OrderStatusId > 3).map((o, i) => {
                          return (
                            <option value={o.OrderStatusId} key={i} > {o.StatusName}</option>
                          )
                        })

                        }
                      </>
                      :
                      <>
                        {orderStatus.filter(child => child.OrderStatusId != row.OrderStatusId && child.OrderStatusId > row.OrderStatusId).map((o, i) => {
                          return (
                            <option value={o.OrderStatusId} key={i} > {o.StatusName}</option>
                          )
                        })

                        }
                      </>
                    }
                  </select>
                ) : (
                  <input
                    type="text"
                    disabled={true}
                    value={
                      row
                        .OrderStatusName
                    }
                    className="form-control"
                  />
                )}
              </Col>
            </div>
            <div className="text-muted" style={{ marginBottom: "20px" }}>
              <div className="row">
                <div className="col-6">
                  Customer <RiUserHeartFill /> : {
                    row.CustomerName} <br />

                  Address <MdLocationPin /> : {row.AddressBook.Address1} <br />
                  To <MdLocalShipping /> : {row.AddressBook.FirstName} {" "} {row.AddressBook.LastName} <br />

                </div>
                <div className="col-6">
                  Phone number <MdPhoneIphone /> : {row.AddressBook.PhoneNumber}<br />
                  Payment <MdPayment /> : {
                    row.PaymentMethodName} <br />
                  Total <CgDollar /> <b>{" : " +
                    row.OrderTotal + ".00 "} </b>

                </div>

              </div>
            </div>

            {row.OrderDetails.map(
              (detail, index) => (
                <div key={index}>


                  <Row
                    key={index + "row"}
                  >
                    <Col md="3">
                      <b>
                        {"Item : " +
                          (index + 1) +
                          " - " +
                          detail
                            .ProductDetails
                            .ProductName}{" "}
                        :
                      </b>
                      <hr className="mt-2" />

                    </Col>

                    <Row>
                      <Col md="2">
                        <img
                          src={
                            Variables.API_PUBLISHED +
                            detail
                              .ProductDetails
                              .ImageUrl
                          }
                          className="w-25 shadow-1-strong rounded mb-4 img-200 image-log"
                          alt="Boat on Calm Water"
                        />
                      </Col>
                      <Col md="2">
                        <b>
                          Quantity :    {detail.Quantity}

                        </b>

                      </Col>
                      <Col md="2">
                        <b>
                          Total :    {detail.TotalPriceItem + ".00 $"}

                        </b>

                      </Col>
                      <Col md="4">
                        <b >
                          Description :    {detail.AttributesValues.map((attValue,index) => (
                            <b key={index} style={{marginLeft: '5px'}}>{attValue}</b>
                          )) }

                        </b>

                      </Col>
                      <Col md="2">
                        <b>
                          Discount :    {detail.Discount + " %"}

                        </b>

                      </Col>



                    </Row>


                  </Row>
                  <hr />
                </div>
              )
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() =>
                CancelUpdate()
              }
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={(e) => SaveChanges()}
            >
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={rejectModal}
          onHide={() => setRejectModal(false)}
        >
          <Modal.Header>
            <Modal.Title>
              Reject Reason
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-5">
              <Col md="6" sm="6" lg="12">
                <label>Reason</label>
                <textarea type="text" value={reason} onChange={(e) => setReason(e.target.value)} className="form-control" autoFocus={true} />
              </Col>

            </Row>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => { setRejectModal(false); setReason(""); }
              }
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={(e) => RejectOrder()}
            >
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>,
    },

  ];

  const handleFilter = (event) => {

    let value = event.target.value;
    setSearch(value)
    let result = [];
    if (value === 0) {
      result = orders;
    } else {
      result = orders.filter((child) =>
        child.OrderStatusId == value
      );
      if (result.length == 0) {
        result = orders;
      }
    }

    setFilteredOrder(result);
  };


  useEffect(() => {
    if(Cookies.get("UserToken") !== undefined){
      GetOrdersStatus();
    GetOrders();
    } else {
      navigate("/Login");
    }
  }, []);

  return (
    <div className="rightSide">
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-12">
            <h2>Orders</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li>Orders</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body px-3">
                <div className="row mb-3">
                  <div className="col-8"></div>
                  <div className="col-4 text-end">
                    <select className="form-select" value={search} onChange={(e) => handleFilter(e)}>
                      <option value={0}>Filter By .. </option>
                      {orderStatus.map((s, i) => {
                        return (
                          <option value={s.OrderStatusId} key={i}> {s.StatusName} </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="bd-example">

                      {ordersLoader === true ? (
                        <CustomSnipper />
                      ) : (
                        <div className="portalTable">
                          <DataTable columns={columns} data={filteredOrder} pagination />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}

export default ListOrders;
