import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Variables } from "../../Variations/Variations";
import { BiEdit, BiShowAlt, BiHide, BiTrash } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineCheck, AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import { Button, Modal } from "react-bootstrap";
import Util from "../../Classes/Util";
import Cookies from "js-cookie";

const Materials = () => {
  const [materials, setmaterials] = useState([]);
  const [show, setShow] = useState(0);
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const token = Cookies.get("UserToken");
  const navigate = useNavigate();
  async function TooglePublishMaterial(id) {
    let response = await fetch(
      Variables.API_URL + "Material/TogglePublish/" + id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      Getmaterials();
    } else {
      toast.error(result);
    }
  }

  const columns = [
    {
      name: "Material ID",
      selector: (row) => row.MaterialId,
      sortable: true,
      reorder: true,
    },
    {
      name: "Material Name",
      selector: (row) => row.Name,
      sortable: true,
      reorder: true,
    },
    {
      name: "Published",
      selector: (row) =>
        row.IsPublished == true ? <AiOutlineCheck /> : <AiOutlineClose />,
    },

    {
      name: "Options",
      selector: (row) => (
        <div>
          {permissions.PublishMaterial && (
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={() => TooglePublishMaterial(row.MaterialId)}
            >
              {row.IsPublished ? (
                <>
                  <BiHide datat-tip="Unpublish" />{" "}
                  <ReactTooltip
                    place="bottom"
                    className="tooltip"
                    effect="solid"
                  />{" "}
                </>
              ) : (
                <>
                  {" "}
                  <BiShowAlt data-tip="Publish" />{" "}
                  <ReactTooltip
                    place="bottom"
                    className="tooltip"
                    effect="solid"
                  />{" "}
                </>
              )}{" "}
            </button>
          )}

          {permissions.ManageMaterial && (<NavLink to={"/Materials/" + row.MaterialId} className="btn btn-outline-warning btn-sm ms-2">
            <BiEdit />
          </NavLink>)}
          {permissions.DeleteMaterial && (<button
            className="btn btn-outline-danger btn-sm ms-2"
            onClick={() => setShow(row.MaterialId)}
          >
            <BiTrash />
          </button>)}
          <Modal show={row.MaterialId === show} onHide={() => setShow(0)}>
            <Modal.Header>
              <Modal.Title>Delete Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <p>
                    Are you sure you want to
                    <b> Delete</b> the material named <b>{row.Name}</b>?
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="container">
                <div className="row">
                  <div className="col-8"></div>
                  <div className="col-2">
                    <Button
                      variant="primary"
                      className="form-control"
                      onClick={() => DeleteMaterial(row.MaterialId)}
                    >
                      Yes
                    </Button>
                  </div>
                  <div className="col-2">
                    <Button
                      variant="secondary"
                      className="form-control"
                      onClick={() => setShow(0)}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];
  async function DeleteMaterial(id) {
    let response = await fetch(
      Variables.API_URL + "Material/DeleteMaterial/" + id,
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://api.playmegastorelb.com/api/",
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      Getmaterials();
    } else {
      toast.error(result);
    }
  }
  async function Getmaterials() {
    const URL = Variables.API_URL + "Material/GetMaterials";
    const response = await fetch(URL,{headers:{
      "Authorization": `Bearer ${token}`,
    }});
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setmaterials(listItems);
  }

  //#region Use Effect
  useEffect(() => {
    if(Cookies.get("UserToken") !== undefined){
      Getmaterials();
    }else{
      navigate("/Login");
    }
  }, []);
  //#endregion Use Effect
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>List Materials</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li>List Materials</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {permissions.ManageMaterial && (<div className="mb-3 text-right">
                  <NavLink to="/Materials/0" className="btn btn-primary">
                    New Material
                  </NavLink>
                </div>)}
                <div className="portalTable">
                  <DataTable columns={columns} data={materials} pagination />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
        />
      </div>
    </div>
  );
};
export default Materials;
