import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { Variables } from "../../Variations/Variations";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
const ManageSmtp = () => {
  const token = Cookies.get("UserToken");
  const [smtp, setSmtp] = useState({
    Email: "",
    Server: "",
    Port: "",
  })
  function IsValid() {
    if (smtp.Server === "") {
      var element = document.getElementById("Server");
      element.classList.add("is-invalid");
      toast.error("Server is required")
      return false;
    }

    if (smtp.Port === "") {
      var element = document.getElementById("Port");
      element.classList.add("is-invalid");
      toast.error("Port is required")
      return false;
    }
    if (smtp.Email === "") {
      var element = document.getElementById("Email");
      element.classList.add("is-invalid");
      toast.error("Email is required")
      return false;

    }
    return true;
  }

  function hanldeInputs(e) {
    const { name, value } = e.target;
    var ele = document.getElementById(name);
    if (value === "") {

      ele.classList.add("is-invalid");
    } else {
      ele.classList.remove("is-invalid");
    }
    setSmtp((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  async function GetSmtp() {
    const URL = Variables.API_URL + "Smtp/GetSMTP/" + 1;
    const response = await fetch(URL,{
      headers:{
        "Authorization": `Bearer ${token}`,
      }
    });
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setSmtp(listItems)
  }


  useEffect(() => {
    GetSmtp();

  }, []);

  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>{smtp.Server == "" ? "Add" : "Update " + smtp.Server}</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Settings">Settings <AiOutlineRight /></Link></li>
              <li>Manage Smtp</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {/* <form> */}
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <label className=" mb-2">Outgoing Server</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Server"
                          id="Server"
                          value={smtp.Server}
                          disabled={true} />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label className=" mb-2">Outgoing Port </label>
                        <input
                          className="form-control"
                          type="text"
                          id="Port"
                          name="Port"
                          value={smtp.Port}
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label className=" mb-2"> Outgoing Email</label>
                        <input
                          className="form-control"
                          id="Email"
                          name="Email"
                          value={smtp.Email}
                          type="Email"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSmtp;
